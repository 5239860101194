import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  default as AndroidButton,
  isSupportedVersionOfAndroid,
} from './Android';
import { default as IOSButton, isSupportedVersionOfiOS } from './IOS';
import { default as LinuxButton, isSupportedVersionOfLinux } from './Linux';
import { default as MacButton, isSupportedVersionOfMac } from './Mac';
import {
  default as WindowsButton,
  isSupportedVersionOfWindows,
} from './Windows';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    borderRadius: theme.spacing.unit * 4,
    width: 'unset',
  },
  separator: {
    margin: '10px',
  },
});

const separator = (style) => <Typography className={style}>- OR -</Typography>;

const feature = (
  elem,
  classes,
  key,
  justify,
  style,
  featureIsPlatform = true
) => [
  <Grid item xs={12} key={key}>
    <Grid container justify={justify}>
      {elem}
    </Grid>
  </Grid>,
  <Grid item xs={12} className={classes.separator} key={'sep'}>
    <Grid container justify={justify}>
      {featureIsPlatform ? separator(style) : null}
    </Grid>
  </Grid>,
];

// TODO: add ids
const nonFeature = (elem, classes, key, justify) => (
  <Grid item md={'auto'} className={classes.button} key={key}>
    <Grid container justify={justify}>
      {elem}
    </Grid>
  </Grid>
);

export const hasDownloadButtonForAgent = (agent) => {
  return (
    isSupportedVersionOfMac(agent) ||
    isSupportedVersionOfWindows(agent) ||
    isSupportedVersionOfLinux(agent) ||
    isSupportedVersionOfAndroid(agent) ||
    isSupportedVersionOfiOS(agent)
  );
};

const DownloadButtons = ({ classes, color, agent, justify, style, size }) => {
  const supported = hasDownloadButtonForAgent(agent);

  // Override parameters when the platform is unsupported
  if (!supported) {
    size = 'small';
  }

  let buttons = [
    {
      component: (
        <Typography className={style}>
          Sorry, your platform or platform version is not currently supported.
          We support the following platforms:
        </Typography>
      ),
      featured: !supported,
      hide: supported,
    },
    {
      component: (
        <WindowsButton
          color={color}
          size={size}
          supported={supported}
          agent={agent}
        />
      ),
      featured: isSupportedVersionOfWindows(agent),
      hide: isSupportedVersionOfMac(agent) || isSupportedVersionOfLinux(agent),
    },
    {
      component: (
        <MacButton
          color={color}
          size={size}
          supported={supported}
          agent={agent}
        />
      ),
      featured: isSupportedVersionOfMac(agent),
      hide:
        isSupportedVersionOfWindows(agent) || isSupportedVersionOfLinux(agent),
    },
    {
      component: (
        <LinuxButton
          color={color}
          size={size}
          supported={supported}
          agent={agent}
        />
      ),
      featured: isSupportedVersionOfLinux(agent),
      hide:
        isSupportedVersionOfWindows(agent) || isSupportedVersionOfMac(agent),
    },
    {
      component: <AndroidButton agent={agent} />,
      featured: isSupportedVersionOfAndroid(agent),
      hide: isSupportedVersionOfiOS(agent),
    },
    {
      component: <IOSButton agent={agent} />,
      featured: isSupportedVersionOfiOS(agent),
      hide: isSupportedVersionOfAndroid(agent),
    },
  ];

  buttons = buttons.filter((button) => !button.hide);

  if (supported) {
    buttons = buttons.filter((button) => button.featured);
  }

  buttons.sort((a, b) => b.featured - a.featured);
  buttons = buttons.map((button, i) =>
    button.featured && buttons.length > 1
      ? feature(button.component, classes, i, justify, style, supported)
      : nonFeature(button.component, classes, i, justify)
  );

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      alignItems="center"
      justify={justify}
    >
      {buttons}
    </Grid>
  );
};

DownloadButtons.defaultProps = {
  justify: 'center',
  style: '',
  size: 'small',
};

DownloadButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end']).isRequired,
  style: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
};

export default withStyles(styles)(DownloadButtons);
