import classnames from 'classnames';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginTop: -theme.spacing.unit / 2,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  primaryLink: {
    color: theme.palette.primary.main,
  },
});

class OverflowMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  _link = (classes, link, key) => (
    <a
      className={classnames(classes.link, {
        [classes.primaryLink]: link.isPrimary,
      })}
      href={link.url}
      key={key}
    >
      <MenuItem>{link.label}</MenuItem>
    </a>
  );

  _linksSeparator = (classes, links) =>
    links.some((l) => l.isFeaturePage) ? <Divider /> : null;

  render() {
    const { classes, links } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {links
            .filter((link) => link.isFeaturePage)
            .map((link, i) => this._link(classes, link, i))}
          {this._linksSeparator(classes, links)}
          {links
            .filter((link) => !link.isFeaturePage)
            .map((link, i) => this._link(classes, link, i))}
        </Menu>
      </div>
    );
  }
}

OverflowMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(OverflowMenu);
