import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.dark.main,
  },
  content: {
    color: theme.palette.getContrastText(theme.palette.dark.main),
    width: '100%',
    padding: theme.spacing.unit * 2,
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const Footer = ({ classes }) => (
  <footer>
    <Toolbar className={classes.root}>
      <Typography
        variant="body1"
        gutterBottom
        align="center"
        className={classes.content}
      >
        <a className={classes.link} href="/privacy-policy">
          {Strings.policy}
        </a>{' '}
        —{' '}
        {Strings.formatString(Strings.copyright, {
          symbol: <span>&copy;</span>,
        })}
      </Typography>
    </Toolbar>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
