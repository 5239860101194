import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import strings from './Strings.js';

const styles = (theme) => ({});

const Policy = ({ classes, locale }) => {
  if (locale) {
    strings.setLanguage(locale);
  }

  return (
    <div>
      <div>
        <Typography paragraph variant="title">
          {strings.Title}
        </Typography>
        <Typography paragraph>{strings.Paragraph1}</Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.Paragraph2,
            <a href="https://www.exede.com/documents/master/website-privacy-policy.pdf">
              {strings.linkHere}
            </a>,
            <a href="https://www.exede.com/legal">{strings.linkHere}</a>
          )}
        </Typography>
        <Typography variant="headline" paragraph>
          {strings.HowServiceWorksTitle}
        </Typography>
        <Typography paragraph>{strings.HowServiceWorksParagraph1}</Typography>
        <Typography variant="headline" paragraph>
          {strings.WhatInformationTitle}
        </Typography>
        <Typography paragraph>{strings.WhatInformationParagraph1}</Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.WhatInformationParagraph2,
            <a href="https://www.exede.com/documents/master/exede-subscriber-privacy-policy.pdf">
              {strings.linkHere}
            </a>,
            <a href="https://www.viasat.com/sites/default/files/media/documents/subscriber-privacy-policy-wb.pdf">
              {strings.linkHere}
            </a>
          )}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.WhatInformationParagraph3,
            <b>{strings.personallyIdentifiableInformation}</b>,
            <b>{strings.pii}</b>
          )}
        </Typography>
        <Typography paragraph>{strings.WhatInformationParagraph4}</Typography>
        <Typography paragraph>{strings.WhatInformationParagraph5}</Typography>
        <Typography variant="headline" paragraph>
          {strings.HowUseInformationTitle}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.HowUseInformationParagraph1,
            <strong>{strings.HowUseInformationEmphasis1}</strong>
          )}
        </Typography>
        <Typography paragraph>{strings.HowUseInformationParagraph2}</Typography>
        <Typography variant="headline" paragraph>
          {strings.HowShareInformationTitle}
        </Typography>
        <Typography paragraph>
          <strong>{strings.HowShareInformationParagraph1}</strong>
        </Typography>
        <Typography paragraph>
          {strings.HowShareInformationParagraph2}
        </Typography>
        <ul>
          <li>
            <Typography>
              {strings.formatString(
                strings.HowShareInformationParagraph3,
                <strong>{strings.serviceProviders}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>{strings.HowShareInformationParagraph4}</Typography>
          </li>
          <li>
            <Typography>{strings.HowShareInformationParagraph5}</Typography>
          </li>
        </ul>
        <Typography paragraph>
          {strings.HowShareInformationParagraph6}
        </Typography>
        <Typography variant="headline" paragraph>
          {strings.ThirdPartyServicesTitle}
        </Typography>
        <Typography paragraph>
          <strong>{strings.ThirdPartyServicesParagraph1}</strong>{' '}
          {strings.ThirdPartyServicesParagraph2}
        </Typography>
        <Typography paragraph>
          {strings.ThirdPartyServicesParagraph3}
        </Typography>
        <ul>
          <li>
            <Typography paragraph>
              {strings.formatString(
                strings.ThirdPartyServicesParagraph4,
                <strong>Google Chrome.</strong>,
                <a href="https://www.google.com/chrome/browser/privacy/">
                  {strings.linkHere}
                </a>
              )}
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              {strings.formatString(
                strings.ThirdPartyServicesParagraph5,
                <strong>Google Search.</strong>,
                <a href="https://www.google.com/">{strings.linkHere}</a>
              )}
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              {strings.formatString(
                strings.ThirdPartyServicesParagraph6,
                <strong>Safe Browsing.</strong>,
                <a href="https://transparencyreport.google.com/safe-browsing">
                  {strings.linkHere}
                </a>
              )}
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              {strings.formatString(
                strings.ThirdPartyServicesParagraph7,
                <strong>uBlock Origin.</strong>,
                <a href="https://www.ublock.org/">{strings.linkHere}</a>
              )}
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              {strings.formatString(
                strings.ThirdPartyServicesParagraph8,
                <strong>{strings.otherThirdParty}</strong>
              )}
            </Typography>
          </li>
        </ul>
        <Typography variant="headline" paragraph>
          {strings.HowSecureInformationTitle}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.HowSecureInformationParagraph1,
            <a href="https://www.iso.org/isoiec-27001-information-security.html">
              ISO/IEC 27001
            </a>,
            <a
              href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-171.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              800-171
            </a>,
            <a
              href="https://csrc.nist.gov/publications/detail/sp/800-122/final"
              target="_blank"
              rel="noopener noreferrer"
            >
              800-122
            </a>
          )}
        </Typography>
        <Typography paragraph>
          {strings.HowSecureInformationParagraph2}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.HowSecureInformationParagraph3,
            <a href="https://www.google.com/transparencyreport/safebrowsing">
              {strings.linkHere}
            </a>
          )}
        </Typography>
        <Typography variant="headline" paragraph>
          {strings.HowProtectPrivacyTitle}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.HowProtectPrivacyParagraph1,
            <a href="https://www.ublock.org/">{strings.linkHere}</a>
          )}
        </Typography>
        <Typography variant="headline" paragraph>
          {strings.USBasedServiceTitle}
        </Typography>
        <Typography paragraph>{strings.USBasedServiceParagraph1}</Typography>
        <Typography variant="headline" paragraph>
          {strings.ChangesToPrivacyNoticeTitle}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.ChangesToPrivacyNoticeParagraph1,
            <a href="https://www.exede.com/legal"> www.exede.com/legal </a>
          )}
        </Typography>
        <Typography variant="headline" paragraph>
          {strings.ContactUsTitle}
        </Typography>
        <Typography paragraph>
          {strings.formatString(
            strings.ContactUsParagraph1,
            <a href="mailto:Privacy@Viasat.com">Privacy@Viasat.com</a>
          )}
        </Typography>
      </div>
    </div>
  );
};

Policy.propTypes = {
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string,
};

export default withStyles(styles)(Policy);
