import CardContent from '@material-ui/core/CardContent';
import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import slugify from 'slugify';
import Typography from '@material-ui/core/Typography';
import urlParse from 'url-parse';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: '#efefef',
      '&:hover': {
        backgroundColor: '#c8c8c8',
      },
    },
    '&:nth-child(even)': {
      borderBottom: 0,
      backgroundColor: '#e1e1e1',
      '&:hover': {
        backgroundColor: '#c8c8c8',
      },
    },
    '&.expanded': {
      backgroundColor: '#c8c8c8',
    },
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.standard,
    }),

    // fallback styles for old browsers
    backgroundColor: theme.palette.background.offset,
    '&:hover': {
      backgroundColor: '#c8c8c8',
    },

    '&:hover .anchor': {
      opacity: 1,
    },
  },
  titleContainer: {
    height: '100%',
    padding: theme.spacing.unit * 2,
  },
  expandContainer: {
    height: '100%',
    padding: theme.spacing.unit * 2,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
    color: theme.palette.primary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  body: {
    backgroundColor: theme.palette.background.primary,
  },
  expandedBody: {
    borderLeft: '#efefef 1px solid',
    borderRight: '#efefef 1px solid',
    borderBottom: '#efefef 1px solid',
  },
  anchorContainer: {
    height: '100%',
  },
  anchor: {
    opacity: 0,
    marginLeft: theme.spacing.unit * 2,
    color: 'grey',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class FAQ extends React.Component {
  constructor(props) {
    super(props);

    const primaryAnchor = slugify(props.name, { lower: true });
    const secondaryAnchor = slugify(props.title, { lower: true });
    const urlHash = urlParse(window.location.href, true).hash.substring(1);

    this.state = {
      expanded:
        props.expanded ||
        primaryAnchor === urlHash ||
        secondaryAnchor === urlHash,
    };
  }

  handleExpandClick = (e) => {
    let expanded = !this.state.expanded;

    // Leave the field expanded if the anchor was clicked
    if (e.target.tagName === 'A') {
      if (typeof e.target.href !== 'undefined') {
        expanded = true;
      }
    }

    this.setState({ expanded: expanded });
  };

  render() {
    const { classes, name, title, body, expanded } = this.props;

    const primaryAnchor = slugify(name, { lower: true });
    const secondaryAnchor = slugify(title, { lower: true });

    return (
      <div
        className={classnames(classes.root, 'faq', {
          expanded: this.state.expanded,
        })}
        id={primaryAnchor}
      >
        <div id={secondaryAnchor}></div>
        <Grid
          container
          wrap="nowrap"
          onClick={(e) => this.handleExpandClick(e)}
        >
          <Grid item xs={true}>
            <Grid
              container
              alignItems="center"
              className={classes.titleContainer}
            >
              <Typography variant="title" className={classes.title}>
                <span>{title}</span>
                <a
                  className={classnames(classes.anchor, 'anchor')}
                  href={'#' + primaryAnchor}
                >
                  #
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              className={classes.expandContainer}
            >
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded,
                })}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Collapse
          in={this.state.expanded || expanded}
          timeout="auto"
          unmountOnExit
          className={classnames({
            [classes.expandedBody]: this.state.expanded,
          })}
        >
          <CardContent className={classes.body}>{body}</CardContent>
        </Collapse>
      </div>
    );
  }
}

FAQ.defaultProps = {
  expanded: false,
};

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
};

export default withStyles(styles)(FAQ);
