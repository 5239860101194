import AppStore from '../images/app-store.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import { withStyles } from '@material-ui/core/styles';
require('useragent/features');

const styles = (theme) => ({
  appStore: {
    height: '44px',
    // paddingTop: '10px',
    margin: '10px',
  },
});

const IOS = ({ classes }) => (
  <a
    href="https://itunes.apple.com/us/app/viasat-browser/id1297568937"
    id="ios-download"
    className="download"
  >
    <img
      className={classes.appStore}
      alt={Strings.download_ios}
      title={Strings.download_ios}
      src={AppStore}
    />
  </a>
);

IOS.propTypes = {
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
};

export default withStyles(styles)(IOS);

const MIN_VERSION = '10.0'; // TODO: seems to be broken...
const SUPPORTED_FAMILIES = ['iOS'];

export const isSupportedVersionOfiOS = (agent) =>
  SUPPORTED_FAMILIES.includes(agent.os.family) &&
  agent.satisfies('>=' + MIN_VERSION);
