import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    and: 'and',
    learn: 'Learn More',
    illustration: 'Illustration',

    links: {
      fast:
        'Get the fastest browsing experience on Viasat Internet to help you get the most out of the web',
      smart: 'Use a smarter browser.',
      private: 'Uncluttered, safe, private browsing.',
      data: 'Reduce wasted data usage as you surf.',
    },

    fast: {
      hero: {
        title: 'A new faster way to browse the web',
        subtitle:
          'Browse up to 3x faster than leading browsers on Viasat Internet.',
      },
      intro: {
        title: 'The fastest browser on Viasat Internet',
        body:
          'No one wants to sit there waiting for slow webpages to load. The Viasat Browser fixes this by loading them faster than other browsers in the market on Viasat Internet, up to as much as thrice as fast.',
        versus: 'See Viasat Browser versus',
      },
      blue: {
        title: 'Fast and Getting Faster',
        body:
          "Viasat's revolutionary browser uses machine learning to browse smarter and faster over time. While some browsers are as fast as they will get at the moment, the Viasat Browser continuously gets better the more people use it.",
      },
      links: {
        title: 'Speed and Much More',
        body:
          "Fast internet shouldn't come at a cost. The Viasat Browser not only adds speed, but other great features as well.",
      },
    },

    private: {
      hero: {
        title:
          'Help protect your digital security and privacy while you browse',
        subtitle:
          'Ad, malware and privacy tracker blocking are the cornerstones to a better, safer way to browse the web.',
      },
      intro: {
        title:
          "Are ads ruining your browsing experience? We believe they shouldn't!",
        body1:
          "Many webpages are bloated, display annoying, intrusive ads that ruin the browsing experience, invade your privacy, expose you to malware, and use way too much data. Trying to read the news shouldn't turn into a game of find the hidden X.",
        body2:
          'The ad blocker in the Viasat Browser helps you reduce the clutter and get back to doing what you want. Plus, this technology helps reduce the amount of data used by each webpage and keeps you more secure by stopping potentially malicious code from running and infecting your computer.',
      },
      blue: {
        title: "Privacy shouldn't just be lip service",
        body1:
          "As the web has become more complex, so have the ways for tracking everything you do on it. A 2017 study revealed that nearly 8 out of 10 web page loads collect your private data. Don't take our word for it, see how many trackers are loaded on the average webpage without our ad blocking enabled.",
        body2:
          'At Viasat, we believe your privacy is incredibly important, and have made that a key aspect of our web browser. Our ad blocking software not only stops ads, but blocks trackers and other tools designed to invade your privacy, keeping your browsing session between you and your computer.',
      },
      links: {
        title: "Privacy shouldn't come at the expense of versatility",
        body:
          'Help protect your privacy and security on the web without sacrificing a best in class web experience.',
      },
    },

    data: {
      hero: {
        title: 'Helps reduce wasted data usage',
        subtitle: 'Features designed to help reduce data consumption.',
      },
      intro: {
        title: 'Unwanted media killing your data?',
        body1:
          "More and more webpages are adding video and audio to make for a more multimedia experience. But what if you aren't interested in that content?",
        body2:
          "With the Viasat Browser's integrated Data Saver Mode, audio and video HTML5 streams are blocked from pre-loading and auto-starting so that you don't consume data for content that you don't want to see.",
      },
      blue: {
        title: 'Stop ads from eating up your data',
        body1:
          'Ads keep getting more and more sophisticated and more data heavy. Now they contain videos, voice overs, animations and more that consume your valuable data as they load.',
        body2:
          'The ad-blocker found in the Viasat Browser can block these ads, saving you from the wasted data they may cause. We want to give the control back to you about whether to load these ads or not and thereby allowing you to determine how your precious data is used.',
      },
      links: {
        title: 'Saves data and much more',
        body:
          "Fast internet shouldn't come at a cost. The Viasat Browser not only adds speed, but other great features as well.",
      },
    },

    smart: {
      hero: {
        title: 'Browsing just got a whole lot smarter',
        subtitle:
          'Never content to do things like everyone else, Viasat has created a new more intelligent browser.',
      },
      intro: {
        title:
          "The internet gets smarter every day, so why shouldn't your browser?",
        body1:
          'Most browsers load the web the same way today that they have for years and innovation has stagnated under the perception of good enough.',
        body2:
          'Viasat, never content to accept the status quo, refused to accept this and has built a browser to use artificial intelligence to get smarter. This technology allows it to learn from every webpage browsed, discover how to load them faster and more efficiently, making your web experience better every day.',
      },
      blue: {
        title: "Studying isn't just for school anymore",
        body1:
          'The only way to continue to get better is through study and learning from your experiences. This is exactly what the Viasat Browser does.',
        body2:
          'The machine learning under the hood continues to study webpages, learn from trends and adjust how it loads webpages. This is how it is able to load webpages up to 3x faster than other browsers on Viasat Internet while also optimizing those pages for the best experience.',
      },
      links: {
        title: 'Smarts and much more',
        body:
          'A focus on making a smarter browser has also driven us to integrate other great features you should check out.',
      },
    },
  },

  'es-MX': {
    and: 'y',
    learn: 'Obtén más información',
    illustration: 'Ilustración',

    links: {
      fast:
        'Vive la experiencia de navegación más rápida en Viasat Internet para que saques el mayor provecho de la web.',
      smart: 'Utiliza un navegador más inteligente.',
      private: 'Navegación privada sin complicaciones y segura.',
      data: 'Reduce el uso de datos mientras navegas.',
    },

    fast: {
      hero: {
        title: 'Una forma más rápida de navegar en la web',
        subtitle:
          'Navega hasta 3 veces más rápido que los navegadores más populares con Viasat Internet.',
      },
      intro: {
        title: 'El navegador más rápido está en Viasat Internet',
        body:
          'A nadie le gusta esperar a que se carguen los sitios web lentos. Viasat Browser soluciona esto cargando más rápido que otros navegadores del mercado mediante Viasat Internet, el cual es hasta el doble de rápido.',
        versus: 'Consulte Viasat Browser contra',
      },
      blue: {
        title: 'Rápido y cada vez más rápido',
        body:
          'El revolucionario navegador de Viasat utiliza aprendizaje automático para navegar más rápido, y para volverse más rápido con el tiempo. Aunque algunos navegadores son lo más rápidos que se puede hasta el día de hoy, el Viasat Browser mejora continuamente entre más sea usado.',
      },
      links: {
        title: 'Velocidad y mucho más',
        body:
          'La conexión rápida a internet no debería tener un costo extra. El Viasat Browser o solo añade velocidad, sino también otras excelentes funciones.',
      },
    },

    private: {
      hero: {
        title:
          'Ayuda a proteger tu seguridad digital y tu privacidad mientras navegas',
        subtitle:
          'Bloquear anuncios de publicidad, malware y rastreadores son los elementos esenciales para navegar la web mejor y de forma más segura.',
      },
      intro: {
        title:
          '¿Los anuncios están arruinando tu experiencia de navegación? ¡Eso no debería pasar!',
        body1:
          'Muchas páginas web están saturadas y exhiben anuncios molestos e invasivos que arruinan la experiencia de navegación, invaden tu privacidad, te exponen a malware y utilizan demasiados datos. Intentar leer las noticias no debería convertirse en un juego de encontrar la X oculta.',
        body2:
          'El bloqueador de anuncios de Viasat Browser te ayuda a reducir el desorden para poder hacer lo que deseas. Además, esta tecnología ayuda a reducir la cantidad de datos utilizados por cada página web y te mantiene más seguro al impedir que se ejecuten códigos maliciosos y que se infecte tu computadora.',
      },
      blue: {
        title: 'La privacidad no deberían ser solo palabras vacías',
        body1:
          'A medida que la web se ha vuelto más compleja, lo mismo sucede con las maneras de rastrear todo lo que haces en ella. Un estudio de 2017 reveló que casi 8 de cada 10 páginas web recopilan tus datos personales. ¿No nos crees? comprueba cuántos rastreadores se cargan en una página web promedio sin habilitar el bloqueo de anuncios.',
        body2:
          'En Viasat, creemos que tu privacidad es sumamente importante, así que hemos eso un aspecto clave de nuestro navegador web. Nuestro software de bloqueo de anuncios no solo detiene los anuncios, sino que bloquea los rastreadores y otras herramientas diseñadas para invadir tu privacidad, manteniendo tu sesión de navegación entre tú y su computadora.',
      },
      links: {
        title: 'La privacidad no debería existir a costa de la versatilidad',
        body:
          'Ayuda a proteger tu privacidad y seguridad en la web sin sacrificar la mejor experiencia web.',
      },
    },

    data: {
      hero: {
        title: 'Ayuda a reducir el uso de datos',
        subtitle:
          'Características diseñadas para ayudar a reducir el consumo de datos.',
      },
      intro: {
        title: '¿El contenido web que no deseas consume todos tus datos?',
        body1:
          'Cada vez más páginas web están añadiendo vídeo y audio para ofrecer una mayor experiencia multimedia. Pero, ¿qué ocurre si no te interesa ese contenido?',
        body2:
          'Con el modo de ahorro de datos integrado en el Viasat Browser, se bloquea el contenido de audio y video HTML5 para evitar que se cargue anticipadamente y se inicie automáticamente, lo cual evita que consumas tus datos en contenido que no deseas ver.',
      },
      blue: {
        title: 'Evita que los anuncios consuman todos tus datos',
        body1:
          'Cada vez los anuncios son más sofisticados y consumen más datos. Ahora contienen videos, voz, animaciones y mucho más, lo cual consume tus valiosos datos a medida que cargan.',
        body2:
          'El bloqueador de anuncios que se encuentra en el Viasat Browser puede bloquear estos anuncios, lo cual ocasiona que ahorres datos que desperdiciarías en dicho contenido. Queremos que tú decidas si cargar estos anuncios o no, lo que te permite determinar cómo se utilizan tus valiosos datos.',
      },
      links: {
        title: 'Ahorra datos y mucho más',
        body:
          'La conexión rápida a internet no debería tener un costo extra. El Viasat Browser no solo añade velocidad, sino también otras excelentes funciones.',
      },
    },

    smart: {
      hero: {
        title: 'La navegación se ha vuelto mucho más inteligente',
        subtitle:
          'Viasat, el navegador que nunca ha estado conforme con hacer las cosas como los demás ha creado un navegador más inteligente.',
      },
      intro: {
        title:
          'El internet se hace más inteligente todos los días, ¿tu navegador no debería ser más inteligente también?',
        body1:
          'La mayoría de los navegadores cargan los sitios web de la misma manera en la que lo han hecho durante años, innovar se ha estancado bajo la percepción de que simplemente es bueno.',
        body2:
          'Viasat nunca se ha conformado con lo que ya existe, se negó a aceptarlo y ha desarrollado un navegador que utiliza inteligencia artificial para volverse más inteligente. Esta tecnología le permite aprender de todas las páginas web exploradas, descubrir cómo cargarlas más rápido y eficientemente, haciendo que tu experiencia web sea mejor cada día.',
      },
      blue: {
        title: 'Estudiar ya no es solo para la escuela',
        body1:
          'La única forma de seguir mejorando es mediante el estudio y el aprendizaje de tus experiencias. Esto es exactamente lo que hace el Viasat Browser.',
        body2:
          'El aprendizaje automático en segundo plano continúa estudiando páginas web, aprende de las tendencias y ajusta cómo carga las páginas web. Así es como Viasat Internet puede cargar páginas web hasta 3 veces más rápido que otros navegadores, además de que optimiza dichas páginas para ofrecer la mejor experiencia.',
      },
      links: {
        title: 'Inteligencia y mucho más',
        body:
          'El objetivo de hacer un navegador más inteligente también nos ha llevado a integrar otras excelentes funciones que deberías conocer.',
      },
    },
  },
};

// Setup aliased locales for all country code formats used by the browser.
['es', 'es_MX', 'es-419', 'es_419'].forEach((code) => {
  strings[code] = strings['es-MX'];
});

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
