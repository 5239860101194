import Banner from './Banner';
import Body from './Body';
import Footnote from './Footnote';
import PropTypes from 'prop-types';
import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

const Home = ({ width, classes, title, subtitle, iconSrc }) => {
  return (
    <div>
      <Banner />
      <Body />
      <Footnote />
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Home));
