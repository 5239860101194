import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    title: 'Viasat Browser FAQs',
    headline:
      "You've come to the right place to learn more about Viasat Browser.",
    contact:
      'If you have problems with your Viasat Internet service, please contact {link}.',
    contact_link: 'customer support',

    general: {
      title: 'Viasat Browser General Info:',

      what: {
        title: 'What is Viasat Browser?',
        body1:
          'Viasat Browser is a new type of web browser that is faster than all leading browsers on Viasat Internet. Plus, it is a browser that crowdsources the Internet allowing it to learn over time. In other words, the more you use it, the better it gets. It also includes an ad-blocking feature which makes it more secure, while also helping to protect your privacy by blocking Internet trackers.',
        body2: 'It is available on Windows, macOS, Android, and iOS.',
      },
      how: {
        title: 'How does Viasat Browser work?',
        body:
          'Viasat Browser was built to find a smarter way to browse the Internet. To this end, we rethought how browsers work and built an innovative hinting system that allows Viasat Browser to study and learn web pages in order to load the Internet in a better, faster way.',
      },
      why: {
        title: 'Why should I install Viasat Browser?',
        body:
          'By using Viasat Browser, you will have both a faster and a safer web experience on Viasat Internet when compared to leading web browsers. Plus, as a member of the Viasat Browser community, you will be helping to make a better web experience for all other users, just by surfing and enjoying the web like you always have, just faster.',
      },
      compare: {
        title: 'How does it compare to other browsers?',
        body_pre:
          "Viasat Browser's features align competitively with leading browsers, but with faster speed over Viasat Internet and robust security and privacy features. Thanks to its innovative hinting technology, it is able to load web pages faster than other leading browsers on Viasat Internet. See for yourself",
        body_link: 'here',
        body_post:
          'In addition, it has an integrated ad-blocker that helps protect you by blocking most malware and Internet trackers. Plus, get the content you love without unnecessary clutter or extra data consumption.',
      },
      exede: {
        title: 'How are Viasat & Exede Internet connected?',
        body: 'Viasat is the owner of Exede Internet.',
      },
      platform: {
        title: 'What platforms is Viasat Browser available for?',
        body: 'Viasat Browser is available for the following platforms:',
        windows: 'Windows 7 or later',
        macos: 'macOS Yosemite 10.10 or later',
        linux: 'Linux (Ubuntu 18.04+, CentOS 7+, Manjaro, and similar)',
        android: 'Android KitKat 4.4 or later',
        ios: 'iOS 11.0 or later',
      },
    },

    basic: {
      title: 'Getting started with Viasat Browser:',

      default: {
        title: 'How do I make Viasat Browser my default browser?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'Open the browser, click on the three-dotted menu in the top right hand corner, select "Settings", then scroll to the bottom and click the button that says "Make Viasat Browser the default browser."',
        },
        android: {
          title: 'Android',
          body:
            'Tap the Settings icon (that looks like a gear), then tap on "Apps & notifications". Tap "Advanced". Tap "Default apps". Tap "Browser app", then tap the list entry for Viasat Browser. Press the Home button to return to the home screen.',
        },
        ios: {
          title: 'iOS',
          body1:
            'Setting Viasat Browser as the default browser on iOS requires iOS 14.',
          body2:
            'Open Viasat Browser. Tap the 3 horizontal dots on the bottom right of the app, then tap "Settings". Click on "Change Default Browser", which will direct you to the iOS settings app. Click on the menu item "Default Browser App" and select Viasat Browser.',
        },
      },
      bookmarks: {
        title: 'How do I import my bookmarks?',
        body:
          'For most desktop browsers, all you need to do is open Viasat Browser, click on {dots} in the top right corner, select {step1} > {step2}. From here, you can choose the browser from which you would like to import history, bookmarks, passwords, and search engines.',
        step1: 'Bookmarks',
        step2: 'Import Bookmarks and Settings...',
        firefox: {
          title:
            'If you are using Firefox Quantum you may need to complete the following additional steps:',
          step1:
            'In Firefox, type Ctrl+Shift+B (⌘⇧B on Mac) and select "Import and Backup" (star dropdown on Mac)',
          step2:
            'Select "Export Bookmarks to HTML" and save the bookmark file somewhere you can easily find it.',
          step3: 'In Viasat Browser, type {link} in the address bar',
          step4:
            'Select "Bookmarks HTML file" from the bottom of the dropdown menu (make sure the "Favorites/Bookmarks" box is checked), then click "Choose File" to import the bookmark file you just saved on your computer.',
        },
        edge: {
          title:
            'If you are using Microsoft Edge you may need to complete the following additional steps:',
          step1:
            'In Microsoft Edge, click on {dots} in the top right corner, select "Settings" then select "Import from another browser".',
          step2:
            'Select "Export to file" and save the bookmark file somewhere you can easily find it.',
          step3: 'In Viasat Browser, type {link} in the address bar',
          step4:
            'Select "Bookmarks HTML file" from the bottom of the dropdown menu (make sure the "Favorites/Bookmarks" box is checked), then click "Choose File" to import the bookmark file you just saved on your computer.',
        },
        mobile:
          'Unfortunately, there is no way to import bookmarks on Android or iOS.',
      },
      chrome: {
        title: 'Why does Viasat Browser look like Google Chrome?',
        body:
          'Like many users, we love Chrome; we just wanted it better and faster. We built Viasat Browser using the same open-source code base, preserving the security features, useful extensions, and look and feel of Chrome, but we added our own innovations to make it better.',
      },
      flash: {
        title: 'How do I run Adobe Flash content in Viasat Browser?',
        body:
          'The Internet has been moving away from using Flash content for years due to security and performance problems. Viasat Browser, like Chrome, has continued this route.',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'For desktop, it is now necessary to select certain sites to allow Flash to run if you need it. To do so, click on the menu {dots} in the top right-hand corner, and go to "Settings". Use the search bar to look for "flash", and it will highlight the "content settings" menu. Select that, and then select the highlighted "flash" menu. This will bring you to a page with "block" and "allow" lists. Add the URL of the site you wish to run Flash on to the Allow list.',
        },
        android: {
          title: 'Android',
          body: 'Viasat Browser has no Flash capabilities on Android.',
        },
        ios: {
          title: 'iOS',
          body: 'Viasat Browser has no Flash capabilities on iOS.',
        },
      },
      search: {
        title: 'How do I change my default search engine?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          step1: 'Click the 3 dot menu to the far right of the address bar.',
          step2: 'Click on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'First, see if the search engine you want to use is already enabled in Viasat Browser by clicking the menu to the right of "Search Engine used when searching from the address bar".',
          step5: 'If it is, select it, and leave the page. You are now set.',
          step6:
            'If it isn\'t, you will have to add it in the "Manage search engines" section.',
          step7:
            'Click the arrow to the right of the "Manage search engines" text.',
          step8: 'Click the ADD button below the default search engines list.',
          step9: 'In the popup window, enter:',
          step9a: 'The name of the search engine in the Search Engine field.',
          step9b: 'A descriptor for the search engine in the Keyword field.',
          step9c:
            'The address of the search engine follow by {query}, for example: {example}',
          step10: 'Click Add.',
          step11:
            'Find the entry you just made in the "Other search engines" list.',
          step12:
            'Click the 3 dots to the right of the entry and select "Make default".',
        },
        android: {
          title: 'Android',
          step1: 'Tap the 3 dot menu to the far right of the address bar.',
          step2: 'Tap on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'Check if the search engine on the menu option is the one you want to use.',
          step5: 'If so, tap Done.',
          step6: 'If not, tap Search Engine.',
          step7:
            'Tap the search engine name that you want from the list of search engines.',
          step8:
            'Tap the left-pointing back arrow until you return to the webview.',
        },
        ios: {
          title: 'iOS',
          step1: 'Tap the 3 dot menu to the far right of the address bar.',
          step2: 'Tap on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'Check if the search engine on the menu option is the one you want to use.',
          step5: 'If so, tap Done.',
          step6: 'If not, tap Search Engine.',
          step7:
            'Tap the search engine name that you want from the list of search engines.',
          step8: 'Tap Done.',
        },
      },
      data: {
        title: 'How do I save data when browsing?',
        body1:
          "Viasat Browser reduces the data you use to surf the web. The Autoplay Blocking feature blocks audio and video HTML5 streams from pre-loading so that you don't automatically consume data for content that you don't listen to or watch. Autoplay Blocking is on by default but you can disable it on a website by toggling the button under the Viasat ball menu.",
        body2:
          'Viasat Browser for iOS and Viasat Browser for Android do not have this feature because mobile devices do not autoplay videos.',
        body3:
          'Youtube users can also benefit from using the Audio Only Youtube extension, if they want to skip the video and just listen to audio. This will save data since only the audio track is used. The extension is available at {link}.',
      },
      update: {
        title: 'How do I update Viasat Browser?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body1:
            'Navigate to the Viasat Browser help page by using the the menu in the top-right of the browser: click {dots} > Help > About Viasat Browser. Alternatively, use the URL bar to go to {link}.',
          body2:
            'Once the page fully loads, if there is a new version available, there will be a "Relaunch" button that looks like this:',
          body3:
            'Click "Relaunch" to restart your browser with the updated version.',
        },
      },
      windows64: {
        title:
          'How do I switch from the Windows 32-bit browser to the 64-bit browser?',
        body1:
          'First, download the installer for the 64-bit Viasat Browser by visiting {link} and clicking "Download Now". {emphasis}. You will first have to uninstall the version of Viasat Browser you currently have installed.',
        body2: 'To uninstall your existing Viasat Browser:',
        uninstall: {
          step1: 'Close any open Viasat Browser windows.',
          step2:
            'Click the Start menu, type "Control Panel", and click the Control Panel option that appears.',
          step3:
            'In the window that opens, under "Programs", click "Uninstall a program".',
          step4:
            'Find Viasat Browser in the list of installed programs and click on it.',
          step5: 'Click the "Uninstall" button at the top of that list.',
          step6:
            'You will see a box asking you to confirm. {emphasis} asking if you want to delete browsing data. If you check this, you will lose saved bookmarks and passwords.',
          emphasis: 'Do NOT check the box',
          step7: 'Reboot your machine.',
        },
        body3:
          'Now that Viasat Browser is uninstalled, you may install the 64-bit version of the browser by running the installer downloaded earlier. ',
        body4:
          'As a precaution, you may perform an AdBlock update as well. This will ensure AdBlock works with the new browser version. To do so, visit {link} and click the "Reload Filters" button.',
        body5:
          'You are good to go! The new browser will continue using your bookmarks and passwords from the previous version.',
        emphasis: 'Do NOT run it yet',
        link: 'the Viasat Browser homepage',
      },
      linux: {
        title: 'How do I install Viasat Browser on a Linux system?',
        body:
          'Viasat Browser for Linux comes in several types of packages. Debian packages are used on systems such as Debian or Ubuntu. RPM packages are used on systems such as Fedora or CentOS. Pacman packages are used in Arch Linux machines such as Manjaro.',
        netflix:
          'Note: that to use Netflix on Viasat Browser you may have to take additional steps. See the {link} for details.',
        netflix_link: 'FAQ entry',
        debian: {
          title: 'Installing Viasat Browser using the Debian package',
          step1:
            'You will first need to add the GPG key which apt will use to verify packages:',
          step2:
            'You will then copy the repository location to your list of sources:',
          step3: 'Now you can install:',
        },
        yum: {
          title: 'Installing Viasat Browser using the RPM package',
          step1:
            'You will first need to add the GPG key which yum will use to verify packages:',
          step2:
            'You will then copy the repository location to your list of sources:',
          step3: 'Now you can install:',
        },
        arch: {
          title: 'Installing Viasat Browser using the Pacman package',
          step1: 'Edit the /etc/pacman.conf file and add the repository:',
          step2: 'Add these lines to the end of the file:',
          step3: 'Now update your pacman sources:',
          step4: 'Now you can install:',
        },
      },
      netflix: {
        title: 'How do I use Netflix in Viasat Browser on Linux?',
        body:
          'Some versions of Viasat Browser do not come with complete support for watching Netflix. This is due to licensing requirements for the redistribution of the Content Delivery Module (CDM).',
        step1:
          'For Viasat Browser version 78 you will need to add complete support for Netflix by manually installing the CDM library for the version of Viasat Browser you are using.',
        step2: 'You first need to download the CDM zip file:',
        step3: 'Now extract the library into the correct place:',
        step4:
          'If you are using Viasat Browser 80 or newer you need to update the Widevine Content Decryption Module component.',
        step5:
          'Go to {link} and select "Check for update" under the "Widevine Content Decryption Module" component. The component should download and install.',
        step6:
          'You must also restart Viasat Browser so it will load the newly installed component.',
        step7: 'Now you can watch Netflix!',
      },
    },

    adblock: {
      title: 'Viasat Browser AdBlock',

      what: {
        title: 'What is Viasat Browser AdBlock?',
        body1:
          'Viasat Browser AdBlock blocks ads, malware, and trackers while you surf the web. This gives you greater security and privacy while loading your pages faster with fewer ads, saving you data.',
        body2:
          'You can tell that Viasat Browser AdBlock is turned on by looking for the green dot in the lower right corner of the Viasat Browser button:',
        body3:
          'Viasat recommends you have AdBlock turned on for all sites to maximize your experience, but if you notice a problem with the site, or want to support the site through its ads, you can easily turn it off on a site by site basis. All you have to do is click the Viasat Browser button to the right of the address bar and click the green toggle that appears.',
        body4:
          'In the same menu, you will find the link to the AdBlock settings where you can choose more advanced settings including enabling a different ad-blocker, or disabling the feature entirely.',
        body5:
          'Viasat Browser AdBlock is available on all platforms running Viasat Browser.',
      },
      why: {
        title: 'Why upgrade to Viasat Browser AdBlock?',
        body1:
          'Viasat Browser AdBlock is a powerful, built-in blocker which blocks ads, malware, and trackers the same as uBlock Origin and other ad-blocking extensions.',
        body2:
          "Unlike extensions, the Viasat Browsers built in AdBlock can operate faster, more efficiently, and won't become disabled due to changes from 3rd parties or Google.",
        body3:
          'Viasat Browser AdBlock uses the same filter lists as other major extension based ad-blockers, meaning you will have the same quality blocking with a better, integrated experience.',
      },
      content: {
        title:
          'How do I report ad content which is handled incorrectly by AdBlock?',
        body0:
          'Viasat Browser AdBlock uses the same filter lists as many popular ad blocking extensions and provides support for most filter styles found in these lists. But there will be cases when a website will have ad content which is not blocked or is missing content which was blocked by mistake. Updates to the public filter lists are done by volunteers who take requests from the public. One place where these requests can be made is the {link} web site.',
        easylistforum_link: 'EasyList Forum',
        body1:
          'To make requests you will need to create an account by registering with the forum. Make sure to read the {rules} concerning how to participate. Once you have an account you can post {new_topics} to the {unblocked} forum or the {incorrectly_removed} forum. Other site members will read your requests and update the public filter lists or make other recommendations.',
        rules: 'Board rules',
        new_topics: 'New Topics',
        unblocked: 'Report unblocked content',
        incorrectly_removed: 'Report incorrectly removed content',
        body2:
          'There are cases where the filter lists are up to date and correct but Viasat Browser AdBlock is still doing something wrong. A good way to figure out if this is the case is by comparing how Chrome works (using an ad blocking extension like uBlock Origin) with Viasat Browser AdBlock. If you think Viasat Browser is doing something wrong please report the issue by {feedback}',
        feedback: 'Sending Feedback',
      },
    },

    programs: {
      title: 'Viasat Browser and Other Programs',

      antivirus: {
        title:
          'My antivirus is trying to block Viasat Browser! What should I do?',
        body:
          "For programs that update regularly, like a web browser, antivirus programs can sometimes block updates initially, despite the fact that they are legitimate programs. If your antivirus program displays an alert for Viasat Browser, you will need to unblock the browser in your antivirus program's settings. If you aren't sure how to do this, try Googling your antivirus program's name and \"allow a blocked program.\"",
      },
      passwords: {
        title:
          "My password manager doesn't work with Viasat Browser. What can I do?",
        body:
          'We are working with various password manager companies, but not all of them support Viasat Browser yet. We have not yet confirmed what password managers work on mobile. Here are the ones that are currently confirmed to work on desktop:',
      },
    },

    trouble: {
      title: 'Troubleshooting',

      update: {
        title: 'Viasat Browser update problems',
        body:
          'If you are having problems updating Viasat Browser on your computer, try the possible fixes below.',

        temporary: {
          title:
            "Viasat Browser can't be updated, because it was opened from a read-only or a temporary location.",
          body:
            'Please {link} and open the downloaded "Viasat Browser.dmg" file. Do not double click on the Viasat Browser icon; instead, drag the Viasat Browser icon into the Applications folder. You can now run the Viasat Browser from your Applications.',
          link: 'redownload the Viasat Browser',
        },
        notary: {
          title:
            "Viasat Browser can't be opened because Apple cannot check it for malicious software.",
          body:
            'For improved security, macOS Catalina requires applications to pass additional security checks. Rest assured, Viasat Browser passes these checks, but it is possible you have an out-of-date version of the browser installed. Please {link} to update to the latest version of the browser.',
          link: 'redownload the Viasat Browser',
        },
        unreachable: {
          title:
            'An error occurred in retrieving update information. Please try again later.',
          body:
            "It's possible the Viasat Browser update servers are temporarily unreachable; try again in a short while.",
        },
        other: {
          title: 'Other update errors.',
          body1:
            'Please check your Internet connection, check that your {antivirus} and make sure your computer meets the minimum {requirements}.',
          body2: 'You may try {download}.',
          body3:
            'If you still experience update issues, please {feedback} and we will assist you.',
          antivirus: 'antivirus is not blocking Viasat Browser',
          requirements: 'system requirements',
          download: 'redownloading the Viasat Browser',
          feedback: 'send us a feedback',
        },
      },
      data: {
        title:
          'On some videos, the controls to start them are missing? What do I do?',

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'The Audio/Video Data Saver Mode that prevents videos from auto-playing may result in the video controls being hidden. To view the video, right click on the image and select "Enable controls." Then press play on the controls like you would previously. If this becomes annoying, then disable the Audio/Video Data Saver Mode in the advanced section of the browser settings.',
        },
        android: {
          title: 'Android',
          body:
            'Viasat Browser for Android does not have the Audio/Video Data Saver feature, so this problem should not occur.',
        },
        ios: {
          title: 'iOS',
          body:
            'Viasat Browser for iOS does not have the Audio/Video Data Saver feature, so this problem should not occur.',
        },
      },
      reach: {
        title:
          "Why can't I reach a site with Viasat Browser, but I can reach it with another browser?",

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'The ad blocker might be the issue, so try disabling it for that page by selecting the Viasat Button (Blue/Green ball in the upper right corner) and selecting the "AdBlock is Enabled" button. If you are using uBlock Origin, you will need to go through the uBlock Origin button (the red shield) and click on the circular On/Off switch. If disabling the ad blocker does not work, try clearing your browser cache and cookies "from the beginning of time" by clicking on the menu {dots} in the right hand corner, navigating to history, and clicking the "Clear browsing data" button.',
        },
      },
      location: {
        title:
          "Why does Viasat Browser think I'm in a different location than my real location?",

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body1:
            'Browsers will often use a location service to figure out their true location or at least make a good guess as to the location of the computer or device which it is running on.',
          body2:
            'Viasat Browser uses a service called GeoIP which is generally pretty good at finding you based on your IP address. But there are some problems with using an IP address to locate your computer.',
          body3:
            'If your internet service is satellite based, for example Viasat Internet, the location of the IP address is more difficult to find since the IP address is assigned at the central ground station end of the satellite link. For situations like this your browser may report that you are located in the middle of a desert, hundreds of miles from your real location.',
          body4:
            'If you find that your location is incorrect or if you want Viasat Browser to provide a different location than what it currently reports, we recommend you install a location spoofing extension. You can find such an extension by going to',
          body5: 'and doing a search for "Location Guard".',
          body6:
            'This extension has been tested by the Viasat Browser team and works by allowing you to set a Fixed Location of your choice. Use the map provided by the extension to find your true location and set the "Fixed Location" blue pin on that spot. That\'s all you need to do. From that point on Viasat Browser will report that as your true location to any web site which asks for it.',
        },
      },
      fast: {
        title: "What do I do if Viasat Browser doesn't seem faster?",
        body:
          'For testing purposes, Viasat Browser sometimes randomly turns off some optimizations to place the browser in a Chromium-like mode in order to test Chromium performance versus Viasat Browser performance. To check if your browser is running in Chromium-like mode, go to {link} and check if the word "Chromiumlike" appears in the command line. If it does, you can restore Viasat Browser optimizations by restarting your browser.',
      },
      black: {
        title: 'Viasat Browser appears as a black screen. What do I do?',
        body:
          'Viasat Browser contains a feature called "hardware acceleration" that, in rare cases, is incompatible with some graphics cards. If your Viasat Browser appears as a black window when you open it, follow these steps to turn off hardware acceleration:',

        desktop: {
          title: 'Desktop (Windows)',
          step1: 'Hit the Start button to open the Start menu.',
          step2:
            'Type in "cmd" and click on "cmd.exe" in the list of options that is displayed, shown here:',
          step3a: 'Enter the following (including the quotation marks):',
          step3b: 'Entering the above text should look like this:',
          step4a:
            'Hit enter and this will open Viasat Browser with the hardware acceleration feature temporarily disabled.',
          step4b:
            'Note: When you hit enter, if you get an error message saying "The system cannot find the path specified", then run this command instead:',
          step5:
            'In Viasat Browser, click the three vertical dots {dots} near the top-right of the window.',
          step6: 'In the menu that opens, click on "Settings".',
          step7:
            'Scroll to the bottom and click on the "Advanced ▼" button. This will show more options.',
          step8a:
            'Scroll to the bottom again, and in the section labeled "System", turn off the toggle labeled "Use hardware acceleration when available".',
          step8b: 'That setting should now look like this:',
          step9:
            'Hardware acceleration is now permanently disabled. Close Viasat Browser and then close the command prompt. You may now open Viasat Browser in the normal manner.',
        },
      },
      snap: {
        title: 'Viasat Browser shows an "Aw, Snap!" on all websites',
        body1:
          'Version 80 of Viasat Browser contains a protective feature called "renderer code integrity", which some Windows antivirus programs interact poorly with. First, be sure that the antivirus program you have installed is up-to-date. If updating the antivirus does not help, you may disable the protective feature.',
        body2:
          'To do so, right-click your Viasat Browser shortcut, and choose "Properties". In the box labeled "Target", add a space after the final quotation mark, then add {flag}. For example, the entire "Target" field may now be:',
      },
      account: {
        title: 'Logging into my Viasat Internet account gives me a SAML error.',
        body1:
          'While not an issue specific to Viasat Browser, we have received a number of complaints about this from customers of Viasat Internet. The error looks like this:',
        body2:
          'If you receive this error, simply open a new tab and navigate to {link}. It should take you to your account page. Then, close the tab with the error.',
      },
    },

    help: {
      title: 'Need more help?',

      feedback: {
        title: 'How do I report an issue to the Viasat Browser team?',
        body1: 'You may report an issue or send us a feedback by clicking the',
        body2:
          'icon in the top-right of your browser window and selecting "Report an issue". In the form that appears, in order for us to be able to reply to you, be sure to include your email address. Provide as much detail as you can on the issue you are experiencing.',
      },
      question: {
        title: "I don't see my question listed. What should I do?",
        body:
          'If you have a question or issue not already addressed, we are here to help. Please {link} and we will get back to you with an answer or solution to help make your Viasat Browser experience the best possible.',
        link: 'send us a feedback',
      },
    },
  },

  'es-MX': {
    title: 'Preguntas frecuentes de Viasat Browser',
    headline:
      'Estás en el lugar adecuado para obtener más información acerca de Viasat Browser.',
    contact:
      'Si tiene problemas con su servicio de Internet Viasat, comuníquese con {link}.',
    contact_link: 'atención al cliente',

    general: {
      title: 'Información general de Viasat Browser:',

      what: {
        title: '¿Qué es Viasat Browser?',
        body1:
          'Viasat Browser es un nuevo tipo de navegador web más rápido que todos los navegadores líderes de Internet de Viasat. Además, es un navegador que recopila información de usuarios en Internet para aprender con el transcurso del tiempo. En otras palabras, cuánto más se lo utilice, mejores resultados se obtienen. Además, incluye una función de bloqueo de anuncios que lo hace más seguro y también ayuda a proteger su privacidad bloqueando los rastreadores de Internet.',
        body2: 'Está disponible en Windows, macOS, Android e iOS.',
      },
      how: {
        title: '¿Cómo funciona Viasat Browser?',
        body:
          'Viasat Browser se diseñó para encontrar una forma más inteligente de explorar Internet. Con este fin, repensamos cómo funcionan los navegadores y diseñamos un sistema innovador que da claves y permite a Viasat Browser estudiar y recordar páginas web para cargar Internet de forma mejor y más rápida.',
      },
      why: {
        title: '¿Por qué debería instalar Viasat Browser?',
        body:
          'Al utilizar Viasat Browser, tendrá una experiencia web más rápida y más segura en Internet de Viasat en comparación con los navegadores web líderes. Además, como miembro de la comunidad de Viasat Browser, ayudará a mejorar la experiencia web de todos los demás usuarios con solo navegar y disfrutar de la web como siempre lo ha hecho; solo que más rápidamente.',
      },
      compare: {
        title: '¿Cómo se compara con otros navegadores?',
        body_pre:
          'Las funciones de Viasat Browser se equiparan de forma competitiva con los navegadores líderes, pero con una velocidad más rápida en Internet de Viasat y una sólida seguridad y características de privacidad. Gracias a su innovadora tecnología que da claves, puede cargar páginas web más rápidamente que otros navegadores líderes en Internet de Viasat. Compruébelo usted mismo',
        body_link: 'aquí',
        body_post:
          'Además, tiene un bloqueador de anuncios integrado que ayuda a protegerle bloqueando la mayor parte del software malicioso y los rastreadores de Internet. Asimismo, consigue el contenido que le gusta sin obstáculos innecesarios ni consumo de datos adicionales.',
      },
      exede: {
        title: '¿Cómo se conectan Internet de Viasat y Exede?',
        body: 'Viasat es el propietario de Internet de Exede.',
      },
      platform: {
        title: '¿Para qué plataformas está disponible Viasat Browser?',
        body: 'Viasat Browser está disponible para las siguientes plataformas:',
        windows: 'Windows 7 o posterior',
        macos: 'macOS Yosemite 10.10 o posterior',
        linux: 'Linux (Ubuntu 18.04+, CentOS 7+, Manjaro, y similares)',
        android: 'Android KitKat 4.4 o posterior',
        ios: 'iOS 11.0 o posterior',
      },
    },

    basic: {
      title: 'Inicio con Viasat Browser:',

      default: {
        title:
          '¿Cómo puedo hacer de Viasat Browser mi navegador predeterminado?',
        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          body:
            'Abra el navegador, haga clic en el menú de tres puntos de la parte superior derecha, seleccione "Ajustes" y a continuación desplácese hasta la parte inferior y haga clic en el botón "Hacer de Viasat Browser el navegador predeterminado".',
        },
        android: {
          title: 'Android',
          body:
            'Pulse el icono de Ajustes (que parece un engranaje) y a continuación pulse "Aplicaciones y notificaciones". Pulse “Avanzado”. Pulse "Aplicaciones predeterminadas". Pulse "Aplicación del navegador" y a continuación pulse la entrada de lista para Viasat Browser. Pulse el botón Inicio para volver a la pantalla de inicio.',
        },
      },
      bookmarks: {
        title: '¿Cómo puedo importar mis favoritos?',
        body:
          'Para la mayor parte de los navegadores de escritorio, todo lo que necesita es abrir Viasat Browser, haga clic en {dots} en la esquina superior derecha, seleccione {step1} > {step2}. Desde aquí, puede elegir el navegador desde el cual desea importar el historial, favoritos, contraseñas y motores de búsqueda.',
        step1: 'Favoritos',
        step2: 'Importar marcadores y ajustes...',
        firefox: {
          title:
            'Si utiliza Firefox Quantum, quizás necesite completar los siguientes pasos adicionales:',
          step1:
            'En Firefox, escriba Ctrl+Mayús+B (⌘⇧B en Mac) y seleccione "Importar y copia de seguridad" (menú desplegable de estrellas en Mac).',
          step2:
            'Seleccione "Exportar Favoritos a HTML" y guarde el archivo de favoritos en algún lugar donde pueda encontrarlo fácilmente.',
          step3:
            'En Viasat Browser, escriba {link} en la barra de direcciones.',
          step4:
            'Seleccione "Archivo HTML de favoritos" en la parte inferior del menú desplegable (asegúrese de que la casilla "Favoritos/Marcadores" esté marcada), haga clic en "Elegir archivo" para importar el archivo de favoritos que acaba de guardar en su computadora.',
        },
        edge: {
          title:
            'Si utiliza Microsoft Edge, quizás necesite completar los siguientes pasos adicionales:',
          step1:
            'En Microsoft Edge, haga clic en {dots} en la esquina superior derecha, seleccione "Ajustes" y, a continuación, seleccione "Importar desde otro navegador"',
          step2:
            'Seleccione "Exportar a archivo" y guarde el archivo de favoritos en algún lugar donde pueda encontrarlo fácilmente.',
          step3:
            'En Viasat Browser, escriba {link} en la barra de direcciones.',
          step4:
            'Seleccione "Archivo HTML de favoritos" en la parte inferior del menú desplegable (asegúrese de que la casilla "Favoritos/Marcadores" esté marcada), haga clic en "Elegir archivo" para importar el archivo de favoritos que acaba de guardar en su computadora.',
        },
        mobile:
          'Lamentablemente, no hay forma de importar favoritos en Android o iOS.',
      },
      chrome: {
        title: '¿Por qué Viasat Browser se parece a Google Chrome?',
        body:
          'Al igual que muchos usuarios, nos encanta Chrome; solo queríamos mejorarlo y hacerlo más rápido. Hemos diseñado Viasat Browser con la misma base de código de fuente abierta, conservamos las características de seguridad, las extensiones útiles y el aspecto de Chrome, pero hemos añadido nuestras propias innovaciones para mejorarlo.',
      },
      flash: {
        title:
          '¿Cómo puedo ejecutar contenido de Adobe Flash en Viasat Browser?',
        body:
          'Internet ha abandonado el uso del contenido de Flash durante muchos años debido a problemas de seguridad y rendimiento. Viasat Browser, como Chrome, ha continuado esta ruta.',
        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          body:
            'En computadoras de escritorio, ahora es necesario seleccionar determinados sitios para permitir que Flash funcione si lo necesita. Para ello, haga clic en el menú {dots} en la esquina superior derecha y vaya a "Ajustes". Utilice la barra de búsqueda para buscar "flash" y se resaltará el menú de "Ajustes de contenido". Selecciónelo y, a continuación, seleccione el menú "flash" resaltado. Esto lo llevará a una página con las listas "bloquear" y "permitir". Agregue la URL del sitio en el que desea ejecutar Flash a la lista Permitir.',
        },
        android: {
          title: 'Android',
          body: 'Viasat Browser no tiene funciones de Flash en Android.',
        },
        ios: {
          title: 'iOS',
          body: 'Viasat Browser no tiene funciones de Flash en iOS.',
        },
      },
      search: {
        title: '¿Cómo cambio mi motor de búsqueda predeterminado?',
        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          step1:
            'Haga clic en el menú de tres puntos a la derecha de la barra de direcciones.',
          step2: 'Haga clic en Ajustes.',
          step3:
            'Desplácese hacia abajo por el menú Ajustes hasta que vea la sección de motor de búsqueda.',
          step4:
            'En primer lugar, compruebe si el motor de búsqueda que desea utilizar ya está habilitado en Viasat Browser haciendo clic en el menú a la derecha de "Motor de búsqueda utilizado al buscar desde la barra de direcciones".',
          step5:
            'Si es así, selecciónelo y abandone la página. Ya está configurado.',
          step6:
            'Si este no es el caso, tendrá que añadirlo en la sección "Gestionar motores de búsqueda".',
          step7:
            'Haga clic en la flecha a la derecha del texto "Gestionar motores de búsqueda".',
          step8:
            'Haga clic en el botón AÑADIR debajo de la lista de motores de búsqueda predeterminada.',
          step9: 'En la ventana emergente, ingrese:',
          step9a: 'Nombre del motor de búsqueda en el campo Motor de búsqueda.',
          step9b: 'Descriptor del motor de búsqueda en el campo Palabra clave.',
          step9c:
            'La dirección del motor de búsqueda seguida por {query}, por ejemplo: {example}',
          step10: 'Haga clic en Añadir.',
          step11:
            'Encuentre la entrada que acaba de realizar en la lista "Otros motores de búsqueda"',
          step12:
            'Haga clic en los tres puntos a la derecha de la entrada y seleccione "Realizar predeterminado".',
        },
        android: {
          title: 'Android',
          step1:
            'Pulse el menú de tres puntos a la derecha de la barra de direcciones.',
          step2: 'Pulse Ajustes.',
          step3:
            'Desplácese hacia abajo por el menú Ajustes hasta que vea la sección de motor de búsqueda.',
          step4:
            'Compruebe si el motor de búsqueda de la opción de menú es el que usted desea usar.',
          step5: 'Si es así, pulse Listo.',
          step6: 'Si no es así, pulse Buscar motor.',
          step7:
            'Pulse el nombre del motor de búsqueda que desee de la lista de motores de búsqueda.',
          step8:
            'Pulse la flecha hacia atrás que apunta hacia la izquierda hasta volver a la vista web.',
        },
        ios: {
          title: 'iOS',
          step1:
            'Haga clic en el menú de tres puntos a la derecha de la barra de direcciones.',
          step2: 'Toque Ajustes.',
          step3:
            'Desplácese hacia abajo por el menú Ajustes hasta que vea la sección de motor de búsqueda.',
          step4:
            'Compruebe si el motor de búsqueda de la opción de menú es el que usted desea usar.',
          step5: 'Si es así, pulse Listo.',
          step6: 'Si no es así, pulse Buscar motor.',
          step7:
            'Pulse el nombre del motor de búsqueda que desee de la lista de motores de búsqueda.',
          step8: 'Toque Listo.',
        },
      },
      data: {
        title: '¿Cuál es el modo de ahorro de datos de audio/video?',
        body1:
          'Viasat Browser reduce los datos que utiliza para navegar por la web. El modo de ahorro de datos de audio/video impide que los flujos de audio y video HTML5 se carguen previamente para que no consuma automáticamente datos para contenido que no escuchará ni verá.',
        body2:
          'Viasat Browser para iOS y Viasat Browser para Android no tienen esta característica debido a limitaciones de la plataforma.',
      },
      update: {
        title: '¿Cómo puedo actualizar Viasat Browser?',
        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          body1:
            'Vaya a la página de ayuda de Viasat Browser mediante el menú en la parte superior derecha del navegador: haga clic en {dots} > Ayuda > Acerca de Viasat Browser. De forma alternativa, utilice la barra URL para ir a {link}.',
          body2:
            'Cuando la página se cargue completamente, si hay una nueva versión disponible, habrá un botón "Reiniciar" que tendrá el siguiente aspecto:',
          body3:
            'Haga clic en "Reiniciar" para reiniciar su navegador con la versión actualizada.',
        },
      },
    },

    adblock: {
      title: 'AdBlock de Viasat Browser',

      what: {
        title: '¿Qué es AdBlock de Viasat Browser?',
        body1:
          'AdBlock de Viasat Browser bloquea anuncios, software malicioso y rastreadores mientras usted navega por la web. Esto le proporciona mayor seguridad y privacidad mientras carga las páginas más rápidamente con menos anuncios, lo que le permite ahorrar datos.',
        body2:
          'Puede decir que AdBlock de Viasat Browser está activado al mirar el punto verde en la esquina inferior derecha del botón de Viasat Browser:',
        body3:
          'Viasat recomienda que AdBlock esté activado para todos los sitios para maximizar su experiencia, pero si observa un problema con el sitio, o si desea respaldar el sitio a través de sus anuncios, puede desactivarlo fácilmente según cada sitio. Solo tiene que hacer clic en el botón de Viasat Browser a la derecha de la barra de direcciones y hacer clic en el botón verde de alternancia que aparece.',
        body4:
          'En el mismo menú, encontrará el enlace a la configuración de AdBlock donde puede elegir ajustes más avanzados y habilitar un bloqueador de anuncios diferente o desactivar la característica por completo.',
        body5:
          'AdBlock de Viasat Browser está disponible en todas las plataformas que funcionan con Viasat Browser.',
      },
      why: {
        title:
          '¿Por qué obtener la versión mejorada de AdBlock de Viasat Browser?',
        body1:
          'AdBlock de Viasat Browser es un potente bloqueador integrado que bloquea los anuncios, el software malicioso y los rastreadores de la misma manera que uBlock Origin y otras extensiones de bloqueo de anuncios.',
        body2:
          'A diferencia de las extensiones, el AdBlock integrado en Viasat Browser puede funcionar más rápidamente y con mayor eficacia y no se desactivará debido a cambios de terceros o Google.',
        body3:
          'AdBlock de Viasat Browser utiliza las mismas listas de filtros que otros bloqueadores de anuncios basados en extensiones, lo cual significa que tendrá la misma calidad de bloqueo con una experiencia mejor e integrada.',
      },
    },

    programs: {
      title: 'Viasat Browser y otros programas',

      antivirus: {
        title:
          '¡Mi antivirus está intentando bloquear Viasat Browser! ¿Qué debo hacer?',
        body:
          'En el caso de programas que se actualizan regularmente, como un navegador web, algunas veces los programas antivirus pueden bloquear actualizaciones inicialmente, a pesar del hecho de que son programas legítimos. Si su programa antivirus muestra una alerta para Viasat Browser, deberá desbloquear el navegador en los ajustes del programa antivirus. Si no está seguro de cómo hacerlo, pruebe escribiendo en Google el nombre del programa antivirus y la frase "permitir programa bloqueado".',
      },
      passwords: {
        title:
          'Mi administrador de contraseñas no funciona con Viasat Browser. ¿Qué puedo hacer?',
        body:
          'Trabajamos con varias empresas de administradores de contraseñas, pero no todas ellas admiten Viasat Browser todavía. Aún no hemos confirmado qué administradores de contraseñas funcionan en los dispositivos móviles. Estos son los que están confirmados actualmente para funcionar en computadoras de escritorio:',
      },
    },

    trouble: {
      title: 'Resolución de problemas',

      update: {
        title: 'Problemas de actualización de Viasat Browser',
        body:
          'Si tiene problemas para actualizar Viasat Browser en su computadora, pruebe las posibles correcciones que se detallan más abajo.',

        temporary: {
          title:
            'Viasat Browser no se puede actualizar porque se abrió desde una versión de solo lectura o desde una ubicación temporal.',
          body:
            '{link} y abra el archivo "Viasat Browser.dmg" descargado. No haga doble clic en el icono de Viasat Browser; en lugar de eso, arrastre el icono de Viasat Browser en la carpeta Aplicaciones. Ahora puede ejecutar Viasat Browser a partir de sus aplicaciones.',
          link: 'Vuelva a descargar Viasat Browser',
        },
        notary: {
          title:
            'Viasat Browser no puede abrirse porque Apple no puede confirmar que no es software malicioso.',
          body:
            'Para mejorar la seguridad, macOS Catalina requiere aplicaciones para aprobar las comprobaciones de seguridad adicionales. Quédese tranquilo que Viasat Browser pasa estas comprobaciones, pero es posible que tenga instalada una versión desactualizada del navegador. {link} para actualizar a la versión más reciente del navegador.',
          link: 'Vuelva a descargar Viasat Browser',
        },
        unreachable: {
          title:
            'Se ha producido un error al recuperar la información de actualización. Inténtelo más tarde.',
          body:
            'Es posible que los servidores de actualizaciones de Viasat Browser estén temporalmente inaccesibles; inténtelo de nuevo en un rato.',
        },
        other: {
          title: 'Otros errores de actualización.',
          body1:
            'Compruebe su conexión a Internet, compruebe que su {antivirus} y asegúrese de que su computadora cumpla con los requisitos {requirements}.',
          body2: 'Puede intentar {download}.',
          body3:
            'Si sigue experimentando problemas de actualización, {feedback} y le ayudaremos.',
          antivirus: 'antivirus no bloquee Viasat Browser',
          requirements: 'mínimos del sistema',
          download: 'volver a descargar Viasat Browser',
          feedback: 'envíenos un comentario',
        },
      },
      data: {
        title:
          'En algunos videos faltan los controles para empezarlos. ¿Qué hago?',

        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          body:
            'El modo de ahorro de datos de audio/video que impide que los videos se reproduzcan de forma automática puede hacer que los controles de video estén ocultos. Para ver el video, haga clic con el botón derecho del ratón en la imagen y seleccione "Habilitar controles". A continuación, pulse el botón de reproducción en los controles como lo hizo anteriormente. Si esto se vuelve molesto, deshabilite el modo de ahorro de audio/video en la sección avanzada de ajustes del navegador.',
        },
        android: {
          title: 'Android',
          body:
            'Viasat Browser para Android no tiene la función de ahorro de datos de audio/video, por lo que este problema no debería ocurrir.',
        },
        ios: {
          title: 'iOS',
          body:
            'Viasat Browser para iOS no tiene la función de ahorro de datos de audio/video, por lo que este problema no debería ocurrir.',
        },
      },
      reach: {
        title:
          '¿Por qué no puedo entrar a un sitio con Viasat Browser, pero puedo ingresar con otro navegador?',

        desktop: {
          title: 'Computadora de escritorio (Windows, macOS, Linux)',
          body:
            'uBlock Origin, el bloqueador de anuncios integrado, puede ser el problema; por lo tanto, pruebe desactivarlo para esa página. Si eso no funciona, pruebe borrar los datos en caché del navegador y las cookies "desde el inicio de todo"; para ello, haga clic en el menú {dots} en la esquina derecha, navegue hasta el historial y haga clic en el botón "Borrar datos de navegación".',
        },
      },
      fast: {
        title: '¿Qué hago si Viasat Browser no parece ser más rápido?',
        body:
          'Con fines de prueba, algunas veces Viasat Browser desactiva de forma aleatoria algunas optimizaciones para colocar el navegador en modo similar a Chromium a fin de probar el rendimiento de Chromium frente al rendimiento de Viasat Browser. Para comprobar si su navegador está funcionando en modo similar a Chromium, vaya a {link} y compruebe si aparece la palabra "Chromiumlike" en la línea de comandos. Si este es el caso, puede restaurar las optimizaciones de Viasat Browser al reiniciar su navegador.',
      },
      black: {
        title: 'Viasat Browser aparece como una pantalla negra. ¿Qué hago?',
        body:
          'Viasat Browser contiene una función llamada "aceleración de hardware" que, en casos poco frecuentes, es incompatible con algunas tarjetas gráficas. Si Viasat Browser aparece como una ventana negra cuando lo abre, siga estos pasos para desactivar la aceleración del hardware:',

        desktop: {
          title: 'Computadora de escritorio (Windows)',
          step1: 'Pulse el botón Inicio para abrir el menú Inicio.',
          step2:
            'Escriba "cmd" y haga clic en "cmd.exe" en la lista de opciones que se muestra aquí:',
          step3a: 'Ingrese lo siguiente (incluidas las comillas):',
          step3b: 'Al ingresar el texto anterior debe verse así:',
          step4a:
            'Pulse Intro y se abrirá Viasat Browser con la función de aceleración de hardware desactivada temporalmente.',
          step4b:
            'Nota: Cuando pulse Intro, si recibe un mensaje de error que dice "El sistema no puede encontrar la ruta especificada", ejecute este comando:',
          step5:
            'En Viasat Browser, haga clic en los tres puntos verticales {dots} cerca de la parte superior derecha de la ventana.',
          step6: 'En el menú que se abre, haga clic en "Ajustes".',
          step7:
            'Desplácese hasta la parte inferior y haga clic en el botón "Avanzado ▼". Esto mostrará más opciones.',
          step8a:
            'Desplácese hasta la parte inferior de nuevo y, en la sección etiquetada "Sistema", desactive el botón de alternancia etiquetado "Usar aceleración de hardware cuando esté disponible".',
          step8b: 'Ese ajuste ahora debería verse de la siguiente manera:',
          step9:
            'La aceleración de hardware ahora está desactivada de forma permanente. Cierre Viasat Browser y luego cierre la petición de comandos. Ahora puede abrir Viasat Browser de la forma normal.',
        },
      },
    },

    help: {
      title: '¿Necesitas más ayuda?',

      feedback: {
        title: '¿Cómo puedo informar un problema al equipo de Viasat Browser?',
        body1:
          'Puede informar un problema o enviarnos un comentario haciendo clic en el icono',
        body2:
          'en la parte superior derecha de la ventana del navegador y seleccione "Informar un problema". En el formulario que aparece, para que podamos responderle, asegúrese de incluir su dirección de correo electrónico. Proporcione todos los detalles que pueda sobre el problema que está experimentando.',
      },
      question: {
        title: 'No veo mi pregunta. ¿Qué debo hacer?',
        body:
          'Si tiene alguna pregunta o problema que todavía no se abordó, estamos aquí para ayudar. Envíenos {link} y nos comunicaremos con usted con una respuesta o solución para ayudar a que su experiencia con Viasat Browser sea la mejor posible.',
        link: 'sus comentarios',
      },
    },
  },
};

// Setup aliased locales for all country code formats used by the browser.
['es', 'es_MX', 'es-419', 'es_419'].forEach((code) => {
  strings[code] = strings['es-MX'];
});

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
