import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  // sm breakpoint
  '@media (max-width: 600px)': {
    root: {
      marginBottom: theme.spacing.unit * 1,
    },
  },
  // // sm breakpoint
  '@media (min-width: 600px)': {
    root: {
      marginBottom: theme.spacing.unit * 5,
    },
  },
  image: {
    maxHeight: '80px',
    margin: theme.spacing.unit * 2,
  },
});

const Highlight = ({ classes, imgSrc, title, body, id }) => (
  <Grid
    container
    className={classes.root}
    alignItems="center"
    alignContent="center"
    id={'highlight-' + id}
  >
    <Grid item xs={12} sm={3}>
      <img src={imgSrc} alt="Highlight icon" className={classes.image} />
    </Grid>

    <Grid item xs={12} sm={9}>
      <Typography paragraph variant="title">
        {title}
      </Typography>
      <div className="highlight-copy">{body}</div>
    </Grid>
  </Grid>
);

Highlight.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  imgSrc: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Highlight);
