import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import HardwareAccelerationCommandPrompt from '../../common/images/faq/hardware_acceleration_command_prompt.png';
import HardwareAccelerationSettingsToggle from '../../common/images/faq/hardware_acceleration_settings_toggle.png';
import HardwareAccelerationStartMenu from '../../common/images/faq/hardware_acceleration_start_menu.png';
import LaunchedFromDMGError from '../../common/images/faq/launched_from_dmg_error.png';
import NotarizationError from '../../common/images/faq/notarization.png';
import PropTypes from 'prop-types';
import React from 'react';
import RelaunchForUpdate from '../../common/images/faq/relaunch_for_update.png';
import Section from './Section';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import UpdateServerDownError from '../../common/images/faq/update_server_down.png';
import ViasatBrowserButton from '../../common/images/faq/viasat_browser_button.png';
import ViasatBrowserButtonMenu from '../../common/images/faq/viasat_browser_button_menu.png';
import ViasatBrowserIcon from '../../common/images/viasat-browser-icon.svg';
import ViasatBrowserUninstall from '../../common/images/faq/viasat_browser_uninstall.png';
import ViasatInternetAccountSamlError from '../../common/images/faq/viasat_internet_account_saml_error.png';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const faqStyles = (theme) => ({
  special: {
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 5px',
    fontWeight: 'bold',
  },
  wideImage: {
    width: '100%',
    maxWidth: '100%',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  image: {
    border: 'solid 1px ' + theme.palette.background.border,
    marginTop: theme.spacing.unit * 3 + 'px',
    marginBottom: theme.spacing.unit * 3 + 'px',
    borderRadius: '4px',
  },
  subFaqTitle: {
    padding: theme.spacing.unit * 2,
    backgroundColor: '#e1e1e1',
  },
  // TODO: create CodeBlock and CodeSpan components in common/
  codeBlock: {
    padding: theme.spacing.unit,
    display: 'block',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: theme.spacing.unit + 'px 0',
  },
  codeBlockBash: {
    display: 'block',
    userSelect: 'all',
    '&::before': {
      content: '"$ "',
    },
  },
  codeBlockText: {
    display: 'block',
    userSelect: 'all',
  },
  codeSpan: {
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 5px',
    whiteSpace: 'nowrap',
  },
});

const FAQS_COPY = [
  {
    title: Strings.general.title,
    faqs: [
      {
        name: 'what-is-viasat-browser',
        title: Strings.general.what.title,
        body: (
          <React.Fragment>
            <Typography component={'span'} paragraph>
              {Strings.general.what.body1}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.general.what.body2}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        name: 'how-viasat-browser-works',
        title: Strings.general.how.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.general.how.body}
          </Typography>
        ),
      },
      {
        name: 'why-use-viasat-browser',
        title: Strings.general.why.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.general.why.body}
          </Typography>
        ),
      },
      {
        name: 'compare',
        title: Strings.general.compare.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.general.compare.body_pre}{' '}
            <a href="/fast">{Strings.general.compare.body_link}</a>.{' '}
            {Strings.general.compare.body_post}
          </Typography>
        ),
      },
      {
        name: 'exede',
        title: Strings.general.exede.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.general.exede.body}
          </Typography>
        ),
      },
      {
        name: 'platform-availability',
        title: Strings.general.platform.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.general.platform.body}
            <ul>
              <li>{Strings.general.platform.windows}</li>
              <li>{Strings.general.platform.macos}</li>
              <li>{Strings.general.platform.linux}</li>
              <li>{Strings.general.platform.android}</li>
              <li>{Strings.general.platform.ios}</li>
            </ul>
          </Typography>
        ),
      },
    ],
  },
  {
    title: Strings.basic.title,
    faqs: [
      {
        name: 'make-viasat-browser-default',
        title: Strings.basic.default.title,
        body: (
          <React.Fragment>
            <Typography variant="subheading" paragraph>
              {Strings.basic.default.desktop.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.basic.default.desktop.body}
            </Typography>
            <Typography variant="subheading" paragraph>
              {Strings.basic.default.android.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.basic.default.android.body}
            </Typography>
            <Typography variant="subheading" paragraph>
              {Strings.basic.default.ios.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.basic.default.ios.body1}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.basic.default.ios.body2}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        name: 'import-bookmarks',
        title: Strings.basic.bookmarks.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.bookmarks.body, {
                  dots: <span className={classes.special}>︙</span>,
                  step1: <em>{Strings.basic.bookmarks.step1}</em>,
                  step2: <em>{Strings.basic.bookmarks.step2}</em>,
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                <em>{Strings.basic.bookmarks.firefox.title}</em>
                <ol>
                  <li>{Strings.basic.bookmarks.firefox.step1}</li>
                  <li>{Strings.basic.bookmarks.firefox.step2}</li>
                  <li>
                    {Strings.formatString(
                      Strings.basic.bookmarks.firefox.step3,
                      {
                        link: (
                          <code className={classes.codeSpan}>
                            viasat://settings/importData
                          </code>
                        ),
                      }
                    )}
                  </li>
                  <li>{Strings.basic.bookmarks.firefox.step4}</li>
                </ol>
              </Typography>
              <Typography component={'span'} paragraph>
                <em>{Strings.basic.bookmarks.edge.title}</em>
                <ol>
                  <li>
                    {Strings.formatString(Strings.basic.bookmarks.edge.step1, {
                      dots: <span className={classes.special}>…</span>,
                    })}
                  </li>
                  <li>{Strings.basic.bookmarks.edge.step2}</li>
                  <li>
                    {Strings.formatString(Strings.basic.bookmarks.edge.step3, {
                      link: (
                        <code className={classes.codeSpan}>
                          viasat://settings/importData
                        </code>
                      ),
                    })}
                  </li>
                  <li>{Strings.basic.bookmarks.edge.step4}</li>
                </ol>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.bookmarks.mobile}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'looks-like-chrome',
        title: Strings.basic.chrome.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.basic.chrome.body}
          </Typography>
        ),
      },
      {
        name: 'flash',
        title: Strings.basic.flash.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.basic.flash.body}
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.flash.desktop.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.flash.desktop.body, {
                  dots: <span className={classes.special}>︙</span>,
                })}
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.flash.android.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.flash.android.body}
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.flash.ios.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.flash.ios.body}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'search-engine',
        title: Strings.basic.search.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                {Strings.basic.search.desktop.title}
              </Typography>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>{Strings.basic.search.desktop.step1}</li>
                  <li>{Strings.basic.search.desktop.step2}</li>
                  <li>{Strings.basic.search.desktop.step3}</li>
                  <li>{Strings.basic.search.desktop.step4}</li>
                  <li>{Strings.basic.search.desktop.step5}</li>
                  <li>{Strings.basic.search.desktop.step6}</li>
                  <li>{Strings.basic.search.desktop.step7}</li>
                  <li>{Strings.basic.search.desktop.step8}</li>
                  <li>{Strings.basic.search.desktop.step9}</li>
                  <ol>
                    <li>{Strings.basic.search.desktop.step9a}</li>
                    <li>{Strings.basic.search.desktop.step9b}</li>
                    <li>
                      {Strings.formatString(
                        Strings.basic.search.desktop.step9c,
                        {
                          query: (
                            <code className={classes.codeSpan}>/?q=%s</code>
                          ),
                          example: (
                            <code className={classes.codeSpan}>
                              https://www.google.com/?q=%s
                            </code>
                          ),
                        }
                      )}
                    </li>
                  </ol>
                  <li>{Strings.basic.search.desktop.step10}</li>
                  <li>{Strings.basic.search.desktop.step11}</li>
                  <li>{Strings.basic.search.desktop.step12}</li>
                </ol>
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.search.android.title}
              </Typography>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>{Strings.basic.search.android.step1}</li>
                  <li>{Strings.basic.search.android.step2}</li>
                  <li>{Strings.basic.search.android.step3}</li>
                  <li>{Strings.basic.search.android.step4}</li>
                  <li>{Strings.basic.search.android.step5}</li>
                  <li>{Strings.basic.search.android.step6}</li>
                  <li>{Strings.basic.search.android.step7}</li>
                  <li>{Strings.basic.search.android.step8}</li>
                </ol>
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.search.ios.title}
              </Typography>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>{Strings.basic.search.ios.step1}</li>
                  <li>{Strings.basic.search.ios.step2}</li>
                  <li>{Strings.basic.search.ios.step3}</li>
                  <li>{Strings.basic.search.ios.step4}</li>
                  <li>{Strings.basic.search.ios.step5}</li>
                  <li>{Strings.basic.search.ios.step6}</li>
                  <li>{Strings.basic.search.ios.step7}</li>
                  <li>{Strings.basic.search.ios.step8}</li>
                </ol>
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'data-saver',
        title: Strings.basic.data.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.basic.data.body1}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.data.body2}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.data.body3, {
                  link: (
                    <code className={classes.codeSpan}>
                      https://chrome.google.com/webstore/detail/audio-only-youtube/pkocpiliahoaohbolmkelakpiphnllog
                    </code>
                  ),
                })}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'update',
        title: Strings.basic.update.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                {Strings.basic.update.desktop.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.update.desktop.body1, {
                  dots: <span className={classes.special}>︙</span>,
                  link: <code className={classes.codeSpan}>viasat://help</code>,
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.update.desktop.body2}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image, classes.wideImage)}
                  src={RelaunchForUpdate}
                  alt="Relaunch to update Viasat Browser"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.basic.update.desktop.body3}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'windows-64',
        title: Strings.basic.windows64.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.windows64.body1, {
                  link: (
                    <a href="/" target="_blank">
                      {Strings.basic.windows64.link}
                    </a>
                  ),
                  emphasis: (
                    <b>
                      <em>{Strings.basic.windows64.emphasis}</em>
                    </b>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.windows64.body2}
                <ol>
                  <li>{Strings.basic.windows64.uninstall.step1}</li>
                  <li>{Strings.basic.windows64.uninstall.step2}</li>
                  <li>{Strings.basic.windows64.uninstall.step3}</li>
                  <li>{Strings.basic.windows64.uninstall.step4}</li>
                  <li>{Strings.basic.windows64.uninstall.step5}</li>
                  <li>
                    {Strings.formatString(
                      Strings.basic.windows64.uninstall.step6,
                      {
                        emphasis: (
                          <b>
                            <em>
                              {Strings.basic.windows64.uninstall.emphasis}
                            </em>
                          </b>
                        ),
                      }
                    )}
                    <div className={classnames(classes.center)}>
                      <img
                        className={classnames(classes.image)}
                        src={ViasatBrowserUninstall}
                        alt="Viasat Browser Uninstall"
                      />
                    </div>
                  </li>
                  <li>{Strings.basic.windows64.uninstall.step7}</li>
                </ol>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.windows64.body3}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.windows64.body4, {
                  link: (
                    <code className={classes.codeSpan}>
                      viasat://resourcelog
                    </code>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.windows64.body5}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'install-linux',
        title: Strings.basic.linux.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.body}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.linux.netflix, {
                  link: (
                    <a href="/faq#netflix-on-linux">
                      {Strings.basic.linux.netflix_link}
                    </a>
                  ),
                })}
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.linux.debian.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.debian.step1}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      wget
                      https://browser.viasat.com/linux/deb/viasat-browser.gpg.key
                    </span>
                    <span className={classes.codeBlockBash}>
                      sudo apt-key add viasat-browser.gpg.key
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.debian.step2}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo wget
                      https://browser.viasat.com/linux/deb/viasat-browser.list
                      -P /etc/apt/sources.list.d
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.debian.step3}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo apt update
                    </span>
                    <span className={classes.codeBlockBash}>
                      sudo apt install viasat-browser-stable
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.linux.yum.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.yum.step1}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      wget
                      https://browser.viasat.com/linux/rpm/viasat-browser.gpg.key
                    </span>
                    <span className={classes.codeBlockBash}>
                      sudo rpm --import viasat-browser.gpg.key
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.yum.step2}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo wget
                      https://browser.viasat.com/linux/rpm/viasat-browser.repo
                      -P /etc/yum.repos.d
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.yum.step3}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo yum install viasat-browser-stable
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.basic.linux.arch.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.arch.step1}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo nano /etc/pacman.conf
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.arch.step2}
                <pre className={classes.codeBlock}>
                  <code>
                    <span>
                      <span className={classes.codeBlockText}>
                        [viasat-browser-stable]
                      </span>
                      <span className={classes.codeBlockText}>
                        SigLevel = Optional TrustAll
                      </span>
                      <span className={classes.codeBlockText}>
                        Server = https://browser.viasat.com/linux/arch
                      </span>
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.arch.step3}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo pacman -Syyu
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.linux.arch.step4}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      sudo pacman -S viasat-browser-stable
                    </span>
                  </code>
                </pre>
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'netflix-on-linux',
        title: Strings.basic.netflix.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.body}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.step1}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.step2}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      wget
                      https://dl.google.com/widevine-cdm/4.10.1582.2-linux-x64.zip
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.step3}
                <pre className={classes.codeBlock}>
                  <code>
                    <span className={classes.codeBlockBash}>
                      unzip 4.10.1582.2-linux-x64.zip libwidevinecdm.so
                    </span>
                    <span className={classes.codeBlockBash}>
                      chmod 755 libwidevinecdm.so
                    </span>
                    <span className={classes.codeBlockBash}>
                      sudo mv libwidevinecdm.so
                      /opt/viasat/viasat-browser/WidevineCdm/_platform_specific/linux_x64/libwidevinecdm.so
                    </span>
                  </code>
                </pre>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.netflix.step4, {
                  link: (
                    <code className={classes.codeSpan}>viasat-browser</code>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.basic.netflix.step5, {
                  link: (
                    <code className={classes.codeSpan}>
                      viasat://components
                    </code>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.step6}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.basic.netflix.step7}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
    ],
  },
  {
    title: Strings.adblock.title,
    faqs: [
      {
        name: 'what-is-adblock',
        title: Strings.adblock.what.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.adblock.what.body1}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.adblock.what.body2}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image)}
                  src={ViasatBrowserButton}
                  alt="Viasat Browser Button"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.adblock.what.body3}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image)}
                  src={ViasatBrowserButtonMenu}
                  alt="Viasat Browser Button Menu"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.adblock.what.body4}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.adblock.what.body5}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'why-use-adblock',
        title: Strings.adblock.why.title,
        body: (
          <React.Fragment>
            <Typography component={'span'} paragraph>
              {Strings.adblock.why.body1}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.adblock.why.body2}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.adblock.why.body3}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        name: 'adblock-content',
        title: Strings.adblock.content.title,
        body: (
          <React.Fragment>
            <Typography component={'span'} paragraph>
              {Strings.formatString(Strings.adblock.content.body0, {
                link: (
                  <a href="https://forums.lanik.us/index.php">
                    {Strings.adblock.content.easylistforum_link}
                  </a>
                ),
              })}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.formatString(Strings.adblock.content.body1, {
                rules: (
                  <a href="https://forums.lanik.us/rules">
                    {Strings.adblock.content.rules}
                  </a>
                ),
                new_topics: <b> {Strings.adblock.content.new_topics} </b>,
                unblocked: <b> {Strings.adblock.content.unblocked} </b>,
                incorrectly_removed: (
                  <b> {Strings.adblock.content.incorrectly_removed} </b>
                ),
              })}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.formatString(Strings.adblock.content.body2, {
                feedback: (
                  <a href="/faq#feedback">{Strings.adblock.content.feedback}</a>
                ),
              })}
            </Typography>
          </React.Fragment>
        ),
      },
    ],
  },
  {
    title: Strings.programs.title,
    faqs: [
      {
        name: 'antivirus',
        title: Strings.programs.antivirus.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.programs.antivirus.body}
          </Typography>
        ),
      },
      {
        name: 'password-managers',
        title: Strings.programs.passwords.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.programs.passwords.body}
            <ul>
              <li>
                <Typography>LastPass</Typography>
              </li>
              <li>
                <Typography>TrueKey</Typography>
              </li>
              <li>
                <Typography>Dashlane</Typography>
              </li>
              <li>
                <Typography>1Password</Typography>
              </li>
            </ul>
          </Typography>
        ),
      },
    ],
  },
  {
    title: Strings.trouble.title,
    faqs: [
      {
        name: 'update-problems',
        title: Strings.trouble.update.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.trouble.update.body}
              </Typography>

              <Typography
                className={classes.subFaqTitle}
                variant="subheading"
                paragraph
              >
                {Strings.trouble.update.temporary.title}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image, classes.wideImage)}
                  src={LaunchedFromDMGError}
                  alt="Launched From DMG Error"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.update.temporary.body, {
                  link: <a href="/">{Strings.trouble.update.temporary.link}</a>,
                })}
              </Typography>

              <Typography
                className={classes.subFaqTitle}
                variant="subheading"
                paragraph
              >
                {Strings.trouble.update.notary.title}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image)}
                  src={NotarizationError}
                  alt="Notarization Error"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.update.notary.body, {
                  link: <a href="/">{Strings.trouble.update.notary.link}</a>,
                })}
              </Typography>

              <Typography
                className={classes.subFaqTitle}
                variant="subheading"
                paragraph
              >
                {Strings.trouble.update.unreachable.title}
              </Typography>
              <div className={classnames(classes.center)}>
                <img
                  className={classnames(classes.image, classes.wideImage)}
                  src={UpdateServerDownError}
                  alt="Update Server Unavailable Error"
                />
              </div>
              <Typography component={'span'} paragraph>
                {Strings.trouble.update.unreachable.body}
              </Typography>

              <Typography
                className={classes.subFaqTitle}
                variant="subheading"
                paragraph
              >
                {Strings.trouble.update.other.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.update.other.body1, {
                  antivirus: (
                    <a href="/faq#antivirus">
                      {Strings.trouble.update.other.antivirus}
                    </a>
                  ),
                  requirements: (
                    <a href="/faq#platform-availability">
                      {Strings.trouble.update.other.requirements}
                    </a>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.update.other.body2, {
                  download: (
                    <a href="/">{Strings.trouble.update.other.download}</a>
                  ),
                })}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.update.other.body3, {
                  feedback: (
                    <a href="/faq#feedback">
                      {Strings.trouble.update.other.feedback}
                    </a>
                  ),
                })}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'video-controls',
        title: Strings.trouble.data.title,
        body: (
          <React.Fragment>
            <Typography variant="subheading" paragraph>
              {Strings.trouble.data.desktop.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.trouble.data.desktop.body}
            </Typography>
            <Typography variant="subheading" paragraph>
              {Strings.trouble.data.android.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.trouble.data.android.body}
            </Typography>
            <Typography variant="subheading" paragraph>
              {Strings.trouble.data.ios.title}
            </Typography>
            <Typography component={'span'} paragraph>
              {Strings.trouble.data.ios.body}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        name: 'unreachable-site',
        title: Strings.trouble.reach.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                {Strings.trouble.reach.desktop.title}
              </Typography>
              <Typography>
                {Strings.formatString(Strings.trouble.reach.desktop.body, {
                  dots: <span className={classes.special}>︙</span>,
                })}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'wrong-location',
        title: Strings.trouble.location.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                {Strings.trouble.location.desktop.title}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body1}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body2}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body3}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body4}
              </Typography>
              <Typography component={'span'} paragraph>
                <code className={classes.codeSpan}>
                  https://chrome.google.com/webstore/category/extensions
                </code>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body5}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.trouble.location.desktop.body6}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'slow-speed',
        title: Strings.trouble.fast.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <Typography component={'span'} paragraph>
              {Strings.formatString(Strings.trouble.fast.body, {
                link: (
                  <code className={classes.codeSpan}>viasat://version</code>
                ),
              })}
            </Typography>
          ))
        ),
      },
      {
        name: 'black-screen',
        title: Strings.trouble.black.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.trouble.black.body}
              </Typography>
              <Typography variant="subheading" paragraph>
                {Strings.trouble.black.desktop.title}
              </Typography>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>{Strings.trouble.black.desktop.step1}</li>
                  <li>
                    {Strings.trouble.black.desktop.step2}
                    <div className={classnames(classes.center)}>
                      <img
                        className={classes.image}
                        src={HardwareAccelerationStartMenu}
                        alt="Hardware Acceleration Start Menu"
                      />
                    </div>
                  </li>
                  <li>
                    <Typography>
                      {Strings.trouble.black.desktop.step3a}
                    </Typography>
                    <pre className={classes.codeBlock}>
                      <code>
                        <span className={classes.codeBlockText}>
                          "C:\Program Files (x86)\ViaSat\Viasat
                          Browser\Application\Viasat Browser.exe" --disable-gpu
                          --disable-accelerated-2d-canvas
                        </span>
                      </code>
                    </pre>
                    <Typography>
                      {Strings.trouble.black.desktop.step3b}
                    </Typography>
                    <div className={classnames(classes.center)}>
                      <img
                        className={classnames(classes.image, classes.wideImage)}
                        src={HardwareAccelerationCommandPrompt}
                        alt="Hardware Acceleration Command Prompt"
                      />
                    </div>
                  </li>
                  <li>
                    <Typography>
                      {Strings.trouble.black.desktop.step4a}
                    </Typography>
                    <Typography>
                      {Strings.trouble.black.desktop.step4b}
                    </Typography>
                    <pre className={classes.codeBlock}>
                      <code>
                        <span className={classes.codeBlockText}>
                          "C:\Program Files\ViaSat\Viasat
                          Browser\Application\Viasat Browser.exe" --disable-gpu
                          --disable-accelerated-2d-canvas
                        </span>
                      </code>
                    </pre>
                  </li>
                  <li>
                    {Strings.formatString(Strings.trouble.black.desktop.step5, {
                      dots: <span className={classes.special}>︙</span>,
                    })}
                  </li>
                  <li>{Strings.trouble.black.desktop.step6}</li>
                  <li>{Strings.trouble.black.desktop.step7}</li>
                  <li>
                    <Typography>
                      {Strings.trouble.black.desktop.step8a}
                    </Typography>
                    <Typography>
                      {Strings.trouble.black.desktop.step8b}
                    </Typography>
                    <div className={classnames(classes.center)}>
                      <img
                        className={classnames(classes.image, classes.wideImage)}
                        src={HardwareAccelerationSettingsToggle}
                        alt="Hardware Acceleration Settings Toggle"
                      />
                    </div>
                  </li>
                  <li>{Strings.trouble.black.desktop.step9}</li>
                </ol>
              </Typography>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'aw-snap',
        title: Strings.trouble.snap.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.trouble.snap.body1}
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.snap.body2, {
                  flag: (
                    <span className={classes.special}>
                      --disable-features=RendererCodeIntegrity
                    </span>
                  ),
                })}
              </Typography>
              <pre className={classes.codeBlock}>
                <code>
                  <span className={classes.codeBlockText}>
                    "C:\Program Files (x86)\ViaSat\Viasat
                    Browser\Application\Viasat Browser.exe"
                    --disable-features=RendererCodeIntegrity
                  </span>
                </code>
              </pre>
            </React.Fragment>
          ))
        ),
      },
      {
        name: 'account-saml',
        title: Strings.trouble.account.title,
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                {Strings.trouble.account.body1}
                <div className={classnames(classes.center)}>
                  <img
                    className={classes.image}
                    src={ViasatInternetAccountSamlError}
                    alt="Single Sign-On Error"
                  />
                </div>
              </Typography>
              <Typography component={'span'} paragraph>
                {Strings.formatString(Strings.trouble.account.body2, {
                  link: (
                    <code className={classes.codeSpan}>
                      <a
                        href="https://account.viasat.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        account.viasat.com
                      </a>
                    </code>
                  ),
                })}
              </Typography>
            </React.Fragment>
          ))
        ),
      },
    ],
  },
  {
    title: Strings.help.title,
    faqs: [
      {
        name: 'feedback',
        title: Strings.help.feedback.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.help.feedback.body1}{' '}
            <img
              src={ViasatBrowserIcon}
              width="18"
              height="18"
              alt="Viasat Browser Icon"
            />{' '}
            {Strings.help.feedback.body2}
          </Typography>
        ),
      },
      {
        name: 'question',
        title: Strings.help.question.title,
        body: (
          <Typography component={'span'} paragraph>
            {Strings.formatString(Strings.help.question.body, {
              link: <a href="/faq#feedback">{Strings.help.question.link}</a>,
            })}
          </Typography>
        ),
      },
    ],
  },
];

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
  },
});

const FAQs = ({ classes }) => (
  <Gutter>
    <Grid container direction="column" className={classes.root}>
      <Gutter always>
        <Typography variant="display3">{Strings.title}</Typography>
        <Typography variant="headline" paragraph>
          {Strings.headline}
        </Typography>
        <Typography variant="headline" paragraph>
          {Strings.formatString(Strings.contact, {
            link: (
              <a href="https://www.exede.com/contact-us/">
                {Strings.contact_link}
              </a>
            ),
          })}
        </Typography>
      </Gutter>
      <Gutter>
        {FAQS_COPY.map((section, i) => (
          <Section key={i} {...section} />
        ))}
      </Gutter>
    </Grid>
  </Gutter>
);

FAQs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQs);
