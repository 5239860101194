import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  titleCase: {
    textTransform: 'capitalize',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
});

const Rules = ({ classes }) => (
  <div>
    <div>
      <Typography variant="title" className={classes.titleCase} paragraph>
        Viasat Browser Reward Sweepstakes Rules
      </Typography>
      <Typography variant="subheading" className={classes.upperCase} paragraph>
        No purchase necessary to enter or win the sweepstakes. All federal,
        state, local and municipal laws and regulations apply. The sweepstakes
        is void where prohibited.
      </Typography>
      <ol>
        <li>
          <Typography paragraph>
            Eligibility – The Viasat Browser Reward Sweepstakes (the
            "Sweepstakes") is open to Viasat Browser users who are legal
            residents of all 50 states and the District of Columbia (excluding
            residents of Puerto Rico, and all other U.S. territories and
            possessions). In order to be eligible for the prize, the entrant
            must download the Viasat Browser and use the Viasat Browser on four
            (4) or more separate days in a calendar month during the Sweepstakes
            Period (defined below). By participating in the Sweepstakes, you
            affirm that (a) you are a legal resident of the United States of
            America, and (b) you are of the legal age of majority in the
            jurisdiction in which you reside (and at least 18 years of age) at
            the time of entry. Employees and immediate family members (spouses,
            children and parents) of Viasat, and their respective subsidiaries
            and affiliates are not eligible to enter the Sweepstakes.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Sweepstake Entries and Drawings - For Viasat Internet subscribers,
            for each month during the Sweepstake Period (defined below) that you
            use the Viasat Browser on four or more separate days, Viasat will
            automatically enter your name for a random drawing. Non-Viasat
            Internet subscribers may enter the Sweepstake by sending a
            self-addressed envelope to Browser Reward Sweepstakes, Viasat, Inc.,
            100 Summer St, Boston, MA 02110, and include your name, address, and
            declaration that you are over the age of 18. This will enter you in
            the contest for the following month after Viasat receives your entry
            request. Viasat will randomly select a winner each month from the
            pool of eligible contestants. The winner for each month will be
            selected during the first week of the following month.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Sweepstake Period – The Sweepstakes contest period will begin at
            12:01 a.m. MST on January 1, 2019 and will end at 11:59 P.M. MDT on
            December 31, 2019 ("Sweepstake Period").
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Prize Announcements –Viasat will announce the winner for each month
            by the 15th of the following month. Viasat will contact the winner
            by email, and the winner will have 48 hours to reply back to accept
            the prize. If the winner does not reply back within 48 hours, the
            winner forgoes the prize, and Viasat will repeat this process to
            select an alternative winner from the eligible entries submitted
            during the relevant contest period.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Rules/Winners' List - For the name of the winner, available after
            February 15, 2019, or for an additional copy of these Sweepstakes
            Rules, mail a self-addressed stamped envelope to: Viasat Browser
            Reward Sweepstakes, Viasat, Inc., 100 Summer St, Boston, MA 02110.
            Please specify the contest name and "Rules" or "Winners' List."
            Requests must be received within six months after the Sweepstakes
            end date. Vermont residents may omit return postage for "Rules"
            requests.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Prize – The prize is a $100 credit towards the winner's Viasat
            Internet Service, which Viasat will apply on the winner's next
            monthly bill. For non-Viasat Internet subscribers, the prize is a
            $100 gift card. No prize substitution is permitted except at
            Viasat's discretion. The prize is nontransferable. Any and all prize
            related expenses, including and without limitation any and all
            federal, state, and/or local taxes, shall be the sole responsibility
            of the winner. Winner agrees to comply with any and all applicable
            local, state and federal laws related to the prize.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Acceptance – By accepting the prize, the winner agrees that Viasat
            may publish the winner's full name in any and all media now or
            hereafter devised worldwide in perpetuity without limitation and
            without additional compensation, notification or permission, unless
            prohibited by law.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Odds –The total number of eligible entries during the Sweepstake
            Period determines the odds of winning the prize.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Sweepstakes Modification – Viasat reserves the right to, at any time
            and without prior notice, terminate the Sweepstakes, in whole or in
            part, or modify the Sweepstakes rules in whole or in part at its
            sole discretion and for any reason.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Agreement to Terms – Participation in the Sweepstakes constitutes
            entrant's full and unconditional acceptance of the Sweepstakes
            rules. Collecting the prize is contingent on the entrant's full
            cooperation with the Sweepstake's rules. The Sweepstakes rules
            constitute the entire agreement between the entrants and Viasat.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Indemnification – By entering the Sweepstakes, the entrant agrees to
            release and hold Viasat, its officers, directors, employees,
            subsidiaries, agents, representatives and affiliates harmless from
            any and all losses, damages, rights, claims and actions of any kind
            in connection with the Sweepstakes or the prize, including without
            limitation, personal injury, death and property damage, and claims
            based on publicity rights, defamation or invasion of privacy.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Governing Law and Arbitration – By entering the Sweepstakes, the
            entrant agrees to be subject to the laws of the State of Colorado in
            any potential dispute. The entrant further agrees that any
            controversy or claim arising out of or relating to the Sweepstakes
            shall be settled by arbitration administered by the American
            Arbitration Association under its Commercial Arbitration Rules.
            There shall be one Arbiter and the place of arbitration shall be
            Denver, Colorado. Judgment on the award rendered by the arbitrator
            may be entered in any court having jurisdiction thereof.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            Miscellaneous Provisions – Limited to one entry per month per
            person. Viasat, its agents and representatives, its parent
            companies, affiliates, and subsidiaries, will not be liable for (i)
            any injuries losses or damages of any kind arising in connection
            with or as a result of the Sweepstakes, participation in the
            Sweepstakes, or the prize; or (ii) any printing or typographical
            error in any material associated with the Sweepstakes.
          </Typography>
        </li>
      </ol>
    </div>
  </div>
);

Rules.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Rules);
