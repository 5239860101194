import Desktop from './Desktop';
import PropTypes from 'prop-types';
import React from 'react';
require('useragent/features');

const EXTERNAL_HOST =
  'https://s3.amazonaws.com/browser.viasat.com/meta-installer/sparrow';
const INTERNAL_HOST =
  'https://s3.amazonaws.com/www.viasatbrowser.com/meta-installer/sparrow';
const INSTALLER = 'viasat_browser_installer_default';

export const Windows = ({ color, size, agent, supported }) => {
  let arch = 'win';
  if (
    agent.source.indexOf('WOW64') !== -1 ||
    agent.source.indexOf('Win64') !== -1
  ) {
    arch = 'win64';
  }

  return (
    <div id="windows-download" className="download">
      <Desktop
        color={color}
        size={size}
        supported={supported}
        minReqOS={{ family: 'Windows', version: MIN_VERSION }}
        urls={{
          Stable: `${EXTERNAL_HOST}/${INSTALLER}_stable_${arch}.exe`,
          Alpha: `${INTERNAL_HOST}/${INSTALLER}_alpha_${arch}.exe`,
          PreAlpha: `${INTERNAL_HOST}/${INSTALLER}_prealpha_${arch}.exe`,
          Canary: `${INTERNAL_HOST}/${INSTALLER}_canary_${arch}.exe`,
        }}
      />
    </div>
  );
};

Windows.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  supported: PropTypes.bool.isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
};

export default Windows;

const MIN_VERSION = '7'; // TODO: seems to be broken...
const SUPPORTED_FAMILIES = ['Windows'];

export const isSupportedVersionOfWindows = (agent) =>
  SUPPORTED_FAMILIES.includes(agent.os.family) &&
  agent.satisfies('>=' + MIN_VERSION);
