import Grid from '@material-ui/core/Grid';
import Highlight from './Highlight';
import IconDevices from '../../common/images/icon/devices.svg';
import IconFast from '../../common/images/icon/fast.svg';
import IconPrivate from '../../common/images/icon/private.svg';
import IconSavesData from '../../common/images/icon/saves-data.svg';
import IconSmart from '../../common/images/icon/smart.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import useragent from 'useragent';
import { Gutter } from '../../Root';
import { isSupportedVersionOfAndroid } from '../../common/DownloadButtons/Android';
import { isSupportedVersionOfiOS } from '../../common/DownloadButtons/IOS';
import { withStyles } from '@material-ui/core/styles';

const HIGHLIGHTS = [
  {
    id: 'fast',
    title: Strings.body.fast.title,
    body: (
      <div>
        <Typography paragraph>{Strings.body.fast.body}</Typography>
        <Typography paragraph>
          <a href="/fast">{Strings.body.fast.link}</a>.
        </Typography>
      </div>
    ),
    imgSrc: IconFast,
    displayOnMobile: true,
  },
  {
    id: 'smart',
    title: Strings.body.smart.title,
    body: <Typography paragraph>{Strings.body.smart.body}</Typography>,
    imgSrc: IconSmart,
    displayOnMobile: true,
  },
  {
    id: 'devices',
    title: Strings.body.devices.title,
    body: <Typography paragraph>{Strings.body.devices.body}</Typography>,
    imgSrc: IconDevices,
    displayOnMobile: false,
  },
  {
    id: 'privacy',
    title: Strings.body.private.title,
    body: <Typography paragraph>{Strings.body.private.body}</Typography>,
    imgSrc: IconPrivate,
    displayOnMobile: true,
  },
  {
    id: 'data',
    title: Strings.body.data.title,
    body: <Typography paragraph>{Strings.body.data.body}</Typography>,
    imgSrc: IconSavesData,
    displayOnMobile: true,
  },
];

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  img: {
    maxWidth: '100%',
  },
});

const Body = ({ classes }) => {
  let agent = useragent.parse(navigator.userAgent);
  let highlights = HIGHLIGHTS;

  if (isSupportedVersionOfAndroid(agent) || isSupportedVersionOfiOS(agent)) {
    highlights = highlights.filter((highlight) => highlight.displayOnMobile);
  }

  const midpoint = Math.ceil(highlights.length / 2);
  const column1 = highlights.slice(0, midpoint);
  const column2 = highlights.slice(midpoint);

  return (
    <Gutter always>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          {column1.map((highlight, i) => (
            <Highlight key={i} {...highlight} />
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {column2.map((highlight, i) => (
            <Highlight key={i} {...highlight} />
          ))}
        </Grid>
      </Grid>
    </Gutter>
  );
};

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Body);
