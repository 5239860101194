import DownloadButtons from '../../common/DownloadButtons';
import FadedDesk from '../../common/images/hero/faded-desk-tiny.jpg';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import ViasatBrowserLogo from '../../common/images/viasat-browser-logo-light.svg';
import classnames from 'classnames';
import useragent from 'useragent';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  // sm styles
  '@media (max-width: 599px)': {
    backgroundImageFadedDesk: {
      backgroundImage: `url(${FadedDesk})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    root: {
      marginTop: '1vh',
      padding:
        '5vh ' +
        theme.spacing.unit * 2 +
        'px 5vh ' +
        theme.spacing.unit * 2 +
        'px',
    },
    logo: {
      maxWidth: '70vw',
      width: '70vw', // firefox
    },
    arrowContainer: {
      padding:
        '10vh ' +
        theme.spacing.unit * 2 +
        'px 1vh ' +
        theme.spacing.unit * 2 +
        'px',
    },
  },
  // hero styles for smaller md screens
  '@media (min-width: 600px) and (max-width: 949px)': {
    backgroundImageFadedDesk: {
      backgroundImage: `url(${FadedDesk})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
  },
  // md styles
  '@media (min-width: 600px)': {
    root: {
      width: '100%',
      marginTop: '1vw',
      padding:
        '10vh ' +
        theme.spacing.unit * 2 +
        'px 0 ' +
        theme.spacing.unit * 2 +
        'px',
    },
    logo: {
      maxWidth: '35vw',
      width: '35vw', // firefox
    },
    arrowContainer: {
      padding:
        '4vh ' +
        theme.spacing.unit * 2 +
        'px 3vh ' +
        theme.spacing.unit * 2 +
        'px',
    },
  },
  // hero style for larger md screens and up
  '@media (min-width: 950px)': {
    backgroundImageFadedDesk: {
      backgroundImage: `url(${FadedDesk})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
  },
  // lg styles
  '@media (min-width: 1280px)': {
    root: {},
    logo: {
      maxWidth: '25vw',
      width: '25vw', // firefox
    },
  },
  rootBackgroundColor: {
    // a background color while the hero image loads
    backgroundImage:
      'linear-gradient(to right, ' +
      theme.palette.home.lightBlue +
      ', ' +
      theme.palette.home.dark +
      ')',
  },
  foreground: {
    color: theme.palette.home.contrastText,
  },
  arrow: {
    left: '50%',
    display: 'block',
    textAlign: 'center',
    fontSize: '20px',
    textDecoration: 'none',
    textShadow: '0',
    width: '13px',
    height: '13px',
    borderBottom: '2px solid #fff',
    borderRight: '2px solid #fff',
    transform: 'rotate(45deg)',
  },
  block: {
    marginBottom: '2vh',
  },
});
const Banner = ({ classes }) => (
  <div className={classes.rootBackgroundColor}>
    <Grid
      container
      justify="space-around"
      alignItems="center"
      className={classnames(
        classes.content,
        classes.root,
        classes.backgroundImageFadedDesk
      )}
      direction="column"
    >
      <div className={classes.block}>
        <Typography align="center">
          <img
            src={ViasatBrowserLogo}
            alt="Viasat Browser logo"
            className={classnames(classes.logo, classes.block)}
          />
        </Typography>
        <Typography
          className={classnames(
            classes.title,
            classes.block,
            classes.foreground
          )}
          variant="display2"
          align="center"
        >
          {Strings.banner.title}
        </Typography>
        <Typography
          className={classnames(
            classes.subtitle,
            classes.block,
            classes.foreground
          )}
          variant="headline"
          align="center"
        >
          {Strings.banner.subtitle}
        </Typography>
      </div>

      <DownloadButtons
        color="secondary"
        style={classes.foreground}
        size="large"
        agent={useragent.parse(navigator.userAgent)}
      />

      <div className={classes.arrowContainer}>
        <div className={classes.arrow}></div>
      </div>
    </Grid>
  </div>
);

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Banner));
