import Footer from './Footer';
import Grid from '@material-ui/core/Grid';
import Gutter from './Gutter';
import Header from './Header';
import PropTypes from 'prop-types';
import React from 'react';
import { default as Routes, ROUTES } from './Routes';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
  },
  content: {
    flexBasis: 'auto', // IE
  },
});

const Page = ({ classes }) => (
  <Grid container direction="column" className={classes.root}>
    <Grid item xs={true} className={classes.content}>
      <Gutter always>
        <Header links={ROUTES.map((r) => r.link)} />
      </Gutter>
      {/* Passing ROUTES to Routes feels a little weird... */}
      <Routes routes={ROUTES} />
    </Grid>
    <Grid item xs={'auto'}>
      <Footer />
    </Grid>
  </Grid>
);

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
