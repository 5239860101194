import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    banner: {
      title: 'A smarter way to browse the Internet',
      subtitle: 'Faster. More Private. Saves Data.',
    },

    body: {
      fast: {
        title: 'The fastest browser on Viasat Internet',
        body:
          'Our top priority with Viasat Browser is to make a faster browser by thinking about the web differently. Already faster than leading browsers, our innovative technology learns how to surf faster the more you use it.',
        link: 'See for yourself',
      },
      smart: {
        title: 'A smarter browser for the evolving Internet',
        body:
          'Viasat built a smarter way to surf the web. Using artificial intelligence, we rethought how browsers work and built new technology that allows Viasat Browser to study and learn webpages in order to load the internet in a better way than other browsers.',
      },
      devices: {
        title: 'Available for all of your devices',
        body:
          'Viasat Browser is available for Windows, macOS, and Linux computers, as well as Android and iOS phones and tablets.',
      },
      private: {
        title: 'A safe browser to protect your privacy & data',
        body:
          'We believe privacy is important and want to help you protect it. With this in mind, the Viasat Browser lets you block unwanted content such as privacy trackers, ads, and malware; all in addition to the security features you already expect while browsing the web. This helps provide you with a safer, more private browsing experience.',
      },
      data: {
        title: 'A browser that helps reduce data usage',
        body:
          "We know how annoying wasting data can be. The Viasat Browser was built to help this. Our ad-blocker stops unwanted ads from loading, and a built-in Data Saver Mode blocks audio and video HTML5 streams from pre-loading so that you don't consume data for content that you don't want to see.",
      },
    },

    footnote: {
      body1:
        'Be one of the first to experience a new kind of web browser and help build a smarter internet that refuses to accept the limitations of today.',
      body2:
        'Viasat Browser takes the best of what came before and expands on it with new technology and thinking to make the internet of tomorrow a reality today.',
    },
  },

  'es-MX': {
    banner: {
      title: 'Una forma más inteligente de navegar por Internet',
      subtitle: 'Más rápido. Más privacidad. Ahorra datos.',
    },

    body: {
      fast: {
        title: 'El navegador más rápido de Viasat Internet',
        body:
          'Nuestra principal enfoque con Viasat Browser es hacer un navegador más rápido pensando en la web de una forma diferente. Nuestros navegadores ya son más rápidos que los principales del mercado, cuentan con nuestra innovadora tecnología que aprende a navegar más rápido entre más se utilice.',
        link: 'Compruébalo tú mismo',
      },
      smart: {
        title: 'Un navegador más inteligente para el Internet en evolución',
        body:
          'Viasat construyó una forma más inteligente de navegar la web. Utilizando inteligencia artificial, rediseñamos cómo funcionan los navegadores y construimos una nueva tecnología que permite a Viasat Browser estudiar y aprender de páginas web con el fin de cargar el contenido de un mejor modo que otros navegadores.',
      },
      devices: {
        title: 'Disponible para todos tus dispositivos',
        body:
          'Viasat Browser está disponible para computadoras Windows y macOS, así como teléfonos y tabletas Android e iOS.',
      },
      private: {
        title: 'Un navegador seguro para proteger tu privacidad & datos',
        body:
          'Creemos que la privacidad es un derecho humano y queremos ayudarte a protegerla. Teniendo eso en cuenta, el Viasat Browser te permite bloquear contenido no deseado, como rastreadores de privacidad, anuncios y malware, además de las funciones de seguridad que ya esperas cuando navegas por la web. Esto te proporciona una experiencia de navegación más segura y privada.',
      },
      data: {
        title: 'Un navegador que ayuda a reducir el uso de datos',
        body:
          'Sabemos lo molesto que es desperdiciar tus datos. El Viasat Browser se ha creado para resolver ese problema. Nuestro bloqueador de anuncios evita que se carguen los anuncios no deseados, y un modo de ahorro de datos integrado bloquea los contenidos de audio y video HTML5 pre-cargados para que no consumas tus datos en contenido que no deseas ver.',
      },
    },

    footnote: {
      body1:
        'Sé uno de los primeros en experimentar un nuevo tipo de navegador web y ayuda a crear un internet más inteligente que se niega a aceptar las limitaciones de hoy en día.',
      body2:
        'Viasat Browser toma lo mejor de lo que ya existe y se revoluciona usando nueva tecnología al tiempo que se enfoca en hacer que internet del mañana sea una realidad hoy en día.',
    },
  },
};

// Setup aliased locales for all country code formats used by the browser.
['es', 'es_MX', 'es-419', 'es_419'].forEach((code) => {
  strings[code] = strings['es-MX'];
});

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
