import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    background:
      'linear-gradient(45deg, ' +
      theme.palette.primary.dark +
      ', ' +
      theme.palette.primary.main +
      ')',
  },
  copy: {
    padding: theme.spacing.unit * 2 + 'px ' + theme.spacing.unit * 3 + 'px',
  },
  blue: {
    color: 'white',
  },
  image: {
    marginBottom: '-4px', // hack to fix weird sizing bug...
  },
});

const Blue = ({ classes, title, body, imgSrc }) => (
  <Grid
    id="blue-section"
    container
    spacing={0}
    alignItems="center"
    className={classes.root}
  >
    <Hidden xsUp={!imgSrc} smDown>
      <Grid item md={'auto'}>
        <img src={imgSrc} alt="Illustration" className={classes.image} />
      </Grid>
    </Hidden>
    <Grid item xs={10} md={true} className={classes.copy}>
      <Typography variant="headline" paragraph classes={{ root: classes.blue }}>
        {title}
      </Typography>
      {body}
    </Grid>
  </Grid>
);

Blue.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.node,
  imgSrc: PropTypes.string,
};

export default withStyles(styles)(Blue);
