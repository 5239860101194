import FAQ from './FAQ';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  title: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.primary.dark,
  },
});

const Section = ({ classes, title, faqs }) => (
  <Grid container direction="column" className={classes.root}>
    <Typography variant="headline" className={classes.title}>
      {title}
    </Typography>
    {faqs.map((faq, i) => (
      <FAQ key={i} {...faq} />
    ))}
  </Grid>
);

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired,
};

export default withStyles(styles)(Section);
