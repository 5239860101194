import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  messageContainer: {
    flexBasis: 'auto', // IE
  },
  messageTypography: {
    color: 'inherit',
  },
  iconContainer: {
    lineHeight: '1em',
  },
  icon: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing.unit * 3,
  },
});

const message = (classes) => (
  <Grid container alignItems="center" id="message-id">
    <Grid item className={classes.iconContainer}>
      <CheckIcon className={classes.icon} />
    </Grid>
    <Grid item xs={true} className={classes.messageContainer}>
      <Typography classes={{ root: classes.messageTypography }}>
        Thanks for downloading Viasat Browser!
      </Typography>
    </Grid>
  </Grid>
);

const Confirmation = ({ classes, open, handleClose }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
  >
    <SnackbarContent
      message={message(classes)}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Confirmation);
