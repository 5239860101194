import BlueFast from '../../common/images/blue/fast.jpg';
import BluePrivate from '../../common/images/blue/secure.jpg';
import BlueSavesData from '../../common/images/blue/saves-data.png';
import BlueSmart from '../../common/images/blue/smart.jpg';
import Feature from './Feature';
import HeroFast from '../../common/images/hero/fast.jpg';
import HeroPrivate from '../../common/images/hero/private.jpg';
import HeroSavesData from '../../common/images/hero/saves-data.png';
import HeroSmart from '../../common/images/hero/smart.jpg';
import IconFast from '../../common/images/icon/fast.svg';
import IconPrivate from '../../common/images/icon/private.svg';
import IconSavesData from '../../common/images/icon/saves-data.svg';
import IconSmart from '../../common/images/icon/smart.svg';
import IntroPrivate from '../../common/images/intro/secure.jpg';
import IntroSavesData from '../../common/images/intro/saves-data.jpg';
import IntroSmart from '../../common/images/intro/smart.jpg';
import React from 'react';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import urlParse from 'url-parse';
import ViasatBrowserShadow from '../../common/images/viasat-browser-shadow.png';
import WistiaVideo, { getVideoInfo } from '../../common/WistiaVideo';
import { withStyles } from '@material-ui/core/styles';

const featureStyles = (theme) => ({
  introImage: {
    maxWidth: '100%', // IE
  },
  blue: {
    color: 'white',
  },
  videoSubtitle: {
    marginTop: theme.spacing.unit * 2 + 'px',
  },
});

// TODO: don't hardcode links

// Anything more complex than paragraphs should be put in its own component.
const FEATURE_COPY = {
  fast: {
    hero: {
      title: Strings.fast.hero.title,
      subtitle: Strings.fast.hero.subtitle,
      iconSrc: IconFast,
      heroSrc: HeroFast,
    },
    intro: {
      title: Strings.fast.intro.title,
      body: (
        <div>
          <Typography paragraph>{Strings.fast.intro.body}</Typography>
        </div>
      ),
      media: React.createElement(
        withStyles(featureStyles)(({ classes }) => {
          let queryVideo = urlParse(window.location.href, true).query.video;
          return (
            <div>
              <Typography align="center" variant="subheading" paragraph>
                {getVideoInfo(queryVideo).title}
              </Typography>
              <WistiaVideo {...getVideoInfo(queryVideo)} autoPlay={false} />
              <Typography
                align="center"
                variant="body1"
                classes={{ root: classes.videoSubtitle }}
              >
                {Strings.fast.intro.versus}{' '}
                {getVideoInfo('*')
                  .filter((v) => v.name !== (queryVideo || getVideoInfo().name))
                  .map((v, i, arr) => (
                    <span>
                      <a href={'?video=' + v.name}>{v.humanName}</a>
                      {i < arr.length - 1 ? ',' : '.'}
                      {arr.length - 2 === i ? ` ${Strings.and} ` : ' '}
                    </span>
                  ))}
              </Typography>
            </div>
          );
        })
      ),
    },
    blue: {
      title: Strings.fast.blue.title,
      body: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <Typography paragraph classes={{ root: classes.blue }}>
            {Strings.fast.blue.body}
          </Typography>
        ))
      ),
      imgSrc: BlueFast,
    },
    links: {
      title: Strings.fast.links.title,
      body: (
        <div>
          <Typography paragraph>{Strings.fast.links.body}</Typography>
          <Typography paragraph>
            {Strings.links.smart} <a href="/smart">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.private} <a href="/private">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.data} <a href="/saves-data">{Strings.learn}</a>.
          </Typography>
        </div>
      ),
      imgSrc: ViasatBrowserShadow,
    },
  },
  private: {
    hero: {
      title: Strings.private.hero.title,
      subtitle: Strings.private.hero.subtitle,
      iconSrc: IconPrivate,
      heroSrc: HeroPrivate,
    },
    intro: {
      title: Strings.private.intro.title,
      body: (
        <div>
          <Typography paragraph>{Strings.private.intro.body1}</Typography>
          <Typography paragraph>{Strings.private.intro.body2}</Typography>
        </div>
      ),
      media: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <img
            className={classes.introImage}
            src={IntroPrivate}
            alt={Strings.illustration}
          />
        ))
      ),
    },
    blue: {
      title: Strings.private.blue.title,
      body: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <div>
            <Typography paragraph>{Strings.private.blue.body1}</Typography>
            <Typography paragraph>{Strings.private.blue.body2}</Typography>
          </div>
        ))
      ),
      imgSrc: BluePrivate,
    },
    links: {
      title: Strings.private.links.title,
      body: (
        <div>
          <Typography paragraph>{Strings.private.links.body}</Typography>
          <Typography paragraph>
            {Strings.links.smart} <a href="/smart">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.private} <a href="/private">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.data} <a href="/saves-data">{Strings.learn}</a>.
          </Typography>
        </div>
      ),
      imgSrc: ViasatBrowserShadow,
    },
  },
  'saves-data': {
    hero: {
      title: Strings.data.hero.title,
      subtitle: Strings.data.hero.subtitle,
      iconSrc: IconSavesData,
      heroSrc: HeroSavesData,
    },
    intro: {
      title: Strings.data.intro.title,
      body: (
        <div>
          <Typography paragraph>{Strings.data.intro.body1}</Typography>
          <Typography paragraph>{Strings.data.intro.body2}</Typography>
        </div>
      ),
      media: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <img
            className={classes.introImage}
            src={IntroSavesData}
            alt={Strings.illustration}
          />
        ))
      ),
    },
    blue: {
      title: Strings.data.blue.title,
      body: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <div>
            <Typography paragraph>{Strings.data.blue.body1}</Typography>
            <Typography paragraph>{Strings.data.blue.body2}</Typography>
          </div>
        ))
      ),
      imgSrc: BlueSavesData,
    },
    links: {
      title: Strings.data.links.title,
      body: (
        <div>
          <Typography paragraph>{Strings.data.links.body}</Typography>
          <Typography paragraph>
            {Strings.links.smart} <a href="/smart">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.private} <a href="/private">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.data} <a href="/saves-data">{Strings.learn}</a>.
          </Typography>
        </div>
      ),
      imgSrc: ViasatBrowserShadow,
    },
  },
  smart: {
    hero: {
      title: Strings.smart.hero.title,
      subtitle: Strings.smart.hero.subtitle,
      iconSrc: IconSmart,
      heroSrc: HeroSmart,
    },
    intro: {
      title: Strings.smart.intro.title,
      body: (
        <div>
          <Typography paragraph>{Strings.smart.intro.body1}</Typography>
          <Typography paragraph>{Strings.smart.intro.body2}</Typography>
        </div>
      ),
      media: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <img
            className={classes.introImage}
            src={IntroSmart}
            alt={Strings.illustration}
          />
        ))
      ),
    },
    blue: {
      title: Strings.smart.blue.title,
      body: React.createElement(
        withStyles(featureStyles)(({ classes }) => (
          <div>
            <Typography paragraph>{Strings.smart.blue.body1}</Typography>
            <Typography paragraph>{Strings.smart.blue.body2}</Typography>
          </div>
        ))
      ),
      imgSrc: BlueSmart,
    },
    links: {
      title: Strings.smart.links.title,
      body: (
        <div>
          <Typography paragraph>{Strings.smart.links.body}</Typography>
          <Typography paragraph>
            {Strings.links.smart} <a href="/smart">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.private} <a href="/private">{Strings.learn}</a>.
          </Typography>
          <Typography paragraph>
            {Strings.links.data} <a href="/saves-data">{Strings.learn}</a>.
          </Typography>
        </div>
      ),
      imgSrc: ViasatBrowserShadow,
    },
  },
};

export const Fast = () => <Feature copy={FEATURE_COPY['fast']} />;
export const Private = () => <Feature copy={FEATURE_COPY['private']} />;
export const SavesData = () => <Feature copy={FEATURE_COPY['saves-data']} />;
export const Smart = () => <Feature copy={FEATURE_COPY['smart']} />;
export const About = () => <Feature copy={FEATURE_COPY['about']} />;

Fast.propTypes = {};
Private.propTypes = {};
SavesData.propTypes = {};
Smart.propTypes = {};
About.propTypes = {};
