import PageStructure from './PageStructure';
import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// https://material.io/tools/color/#!/?&primary.color=009FE3&secondary.color=BED733
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#62d0ff',
      main: '#009fe3',
      dark: '#0071b1',
      contrastText: '#fff',
      overrides: {
        MuiButton: {
          raisedPrimary: {
            color: '#fff',
          },
        },
      },
    },
    secondary: {
      light: '#f3ff69',
      main: '#bed733',
      dark: '#8aa600',
      contrastText: '#000',
      overrides: {
        MuiButton: {
          raisedPrimary: {
            color: '#000',
          },
        },
      },
    },
    dark: {
      main: '#202e39',
    },
    background: {
      offset: '#f1f5f8',
      primary: '#ffffff',
      code: '#ececec',
      border: '#e0e0e0',
    },
    home: {
      dark: '#015274',
      light: '#0d7e82',
      lightBlue: '#0f78b2',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 16,
  },
});

class Theme extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <PageStructure />
      </MuiThemeProvider>
    );
  }
}

export default Theme;
