import {
  default as DownloadButtons,
  hasDownloadButtonForAgent,
} from '../../common/DownloadButtons';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import useragent from 'useragent';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  highlight: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing.unit * 5,
  },
  block: {
    marginBottom: '2vh',
  },
  downloadButton: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Body = ({ classes }) => {
  let agent = useragent.parse(navigator.userAgent);

  let bottomText = (
    <div>
      <Typography className={classes.block} paragraph>
        {Strings.footnote.body1}
      </Typography>
      <Typography className={classes.block} paragraph>
        {Strings.footnote.body2}
      </Typography>
    </div>
  );

  let bottomContainer = hasDownloadButtonForAgent(agent) ? (
    <Grid container direction="row" justify="space-evenly">
      <Grid item xs={12} md={6}>
        {bottomText}
      </Grid>
      <Grid item xs={12} md={6} className={classes.downloadButton}>
        <DownloadButtons color="primary" agent={agent} />
      </Grid>
    </Grid>
  ) : (
    <Grid item align="center">
      {bottomText}
    </Grid>
  );

  return (
    <div>
      <Grid item className={classes.highlight}>
        <Gutter>{bottomContainer}</Gutter>
      </Grid>
    </div>
  );
};

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Body);
