import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

const Gutter = ({ children, always }) => (
  <Grid container>
    <Grid item xs={always ? 1 : false} sm={1} lg={2} />
    <Grid item xs={always ? 10 : 12} sm={10} lg={8}>
      {children}
    </Grid>
    <Grid item xs={always ? 1 : false} sm={1} lg={2} />
  </Grid>
);

Gutter.propTypes = {
  always: PropTypes.bool,
};

export default withStyles(styles)(Gutter);
