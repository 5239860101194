import Menu from './Menu';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import logos from '../../common/images/viasat-logo.svg';
import OverflowMenu from './OverflowMenu';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit,
  },
  logos: {
    height: theme.spacing.unit * 4,
  },
});

const Header = ({ classes, links }) => {
  return (
    <Toolbar className={classes.root} disableGutters>
      <Grid container>
        <Grid item xs={2}>
          <img src={logos} alt="Viasat Internet" className={classes.logos} />
        </Grid>
        <Grid item xs={10}>
          <Grid container justify="flex-end" wrap="nowrap">
            <Hidden mdUp>
              <OverflowMenu links={links.filter((link) => !link.hide)} />
            </Hidden>
            <Hidden smDown>
              <Menu links={links.filter((link) => !link.hide)} />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default withStyles(styles)(Header);
