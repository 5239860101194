import PropTypes from 'prop-types';
import React from 'react';

const DocumentTitle = ({ title, children }) => {
  document.title = title;
  return <div>{children}</div>;
};

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
