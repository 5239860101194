import Desktop from './Desktop';
import PropTypes from 'prop-types';
import React from 'react';
require('useragent/features');

const Mac = ({ color, size, agent, supported }) => (
  <div id="mac-download" className="download">
    <Desktop
      color={color}
      size={size}
      supported={supported}
      minReqOS={{ family: 'macOS', version: MIN_VERSION }}
      urls={{
        Stable:
          'https://s3.amazonaws.com/browser.viasat.com/sparkle/Sparrow/stable/ViasatBrowserLatest.dmg',
        Alpha:
          'https://s3.amazonaws.com/www.viasatbrowser.com/sparkle/Sparrow/alpha/ViasatBrowserLatest.dmg',
        PreAlpha:
          'https://s3.amazonaws.com/www.viasatbrowser.com/sparkle/Sparrow/prealpha/ViasatBrowserLatest.dmg',
        Canary:
          'https://s3.amazonaws.com/www.viasatbrowser.com/sparkle/Sparrow/canary/ViasatBrowserLatest.dmg',
      }}
    />
  </div>
);

Mac.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  supported: PropTypes.bool.isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
};

export default Mac;

const MIN_VERSION = '10.10.0'; // TODO: seems to be broken...
const SUPPORTED_FAMILIES = ['Mac OS X'];

export const isSupportedVersionOfMac = (agent) =>
  SUPPORTED_FAMILIES.includes(agent.os.family) &&
  agent.satisfies('>=' + MIN_VERSION);
