import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing.unit * 6,
    color: theme.palette.error.main,
    display: 'inline-block',
  },
  message: {
    flexBasis: 'auto', // IE
  },
});

const NotFound = ({ classes }) => (
  <Grid container>
    <Grid item>
      <ErrorOutlineIcon className={classes.icon} />
    </Grid>
    <Grid item xs={true} className={classes.message}>
      <Typography variant="subheading" paragraph>
        We don't know how you got here (and won't ask), but we're afraid we have
        nothing for you.
      </Typography>
      <Typography>Try a link in the menu above.</Typography>
    </Grid>
  </Grid>
);

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
