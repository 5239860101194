import Eula from './Eula';
import { Gutter } from '../../Root';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

const License = ({ classes, match }) => (
  <Gutter>
    <Eula locale={match.params.locale} />
  </Gutter>
);

License.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(License);
