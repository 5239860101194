import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    title: 'Using container home screens on Viasat Browser',

    install:
      'If you do not already have Viasat Browser installed on your mobile device, you may install it from:',
    android: 'The Android {link}',
    android_store: 'Play Store',
    ios: 'The iOS {link}',
    ios_store: 'App Store',

    configure: 'To configure Viasat Browser to use a container home screen:',
    step1: 'Open Viasat Browser on your mobile device',
    step2: 'Navigate to {link}',
    step3: 'In the search box on top of this page, search for {search}',
    step4:
      'A single option will be displayed, titled {title}, as in the below image:',
    step5: 'Select the drop down menu and choose one of the following options:',
    step5a1: 'Viasat App Container (Experiment variant 1)',
    step5a2:
      'This variant will show 3 tiles with links to Viasat apps: My Viasat, Stream On, and Shield',
    step5b1: 'Viasat App Container (Experiment variant 2)',
    step5b2:
      'This variant will show the My Viasat app with specific deep links for account, billing, etc., as well as the Stream On and Shield apps.',
    step6: 'Restart Viasat Browser',
    step6a:
      'On Android, a Relaunch button will appear at the bottom of your screen. Click that button.',
    step6b:
      'On iOS, swipe up from the bottom of your screen to see an overview of all apps you have open. Then swipe up on the Viasat Browser app to close it. Relaunch Viasat Browser from your home screen.',
    step7:
      'Now each new tab you open in the Viasat Browser will use the home screen you selected above.',

    help:
      'For any questions or help, please reach out to the Viasat Browser team at {link}.',
  },
};

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
