import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import Strings from './Strings';
import Typography from '@material-ui/core/Typography';
import { Eula } from '../../Pages/License';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  eula: {
    maxHeight: '140px',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.offset,
    border: '1px solid #cccccc',
    padding: theme.spacing.unit * 3,
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    borderRadius: '25px',
    margin: '10px',
  },
  buttonLabel: {
    textTransform: 'capitalize',
    color: 'white',
    padding: '4px',
  },
  buttonIcon: {
    marginRight: '10px',
  },
  formControl: {
    marginRight: '20px',
    minWidth: 160,
  },
});

const Agreement = ({
  classes,
  minReqOS,
  urls,
  open,
  handleClose,
  handleAgree,
}) => {
  const isStableHost =
    window.location.host === 'browser.viasat.com' ||
    // Behave like the stable host if only one download URL was given.
    Object.keys(urls).length === 1;

  const channels = Object.keys(urls);
  const [channel, setChannel] = React.useState(
    isStableHost ? channels[0] : channels[1]
  );

  let channelSelection;

  // On browser.dev.viasat.com, we allow users to select the channel they wish
  // to download. On browser.viasat.com, users are defaulted to the Stable
  // channel and may not select a different channel.
  if (!isStableHost) {
    const handleChange = (event) => {
      setChannel(event.target.value);
    };

    channelSelection = (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{Strings.select_channel}</InputLabel>
        <Select value={channel} onChange={handleChange}>
          {channels.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  let subheading;

  if (minReqOS.version.length > 0) {
    subheading = (
      <DialogContent>
        <Typography variant="subheading">
          {Strings.formatString(Strings.download_for_long, {
            target: minReqOS.family,
            version: minReqOS.version,
          })}
        </Typography>
      </DialogContent>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="download-dialog-title"
    >
      <DialogTitle disableTypography id="download-dialog-title">
        <Typography variant="headline">
          {Strings.formatString(Strings.download_for_long, {
            target: minReqOS.family,
          })}
        </Typography>
      </DialogTitle>
      {subheading}
      <DialogContent>
        <div className={classes.eula}>
          <Eula locale={Strings.getLanguage()} />
        </div>
      </DialogContent>
      <DialogActions>
        {channelSelection}

        <a href={urls[channel]} className={classes.link}>
          <Button
            id="desktop-download"
            variant="contained"
            color="primary"
            className={classes.button}
            classes={{ text: classes.buttonText, label: classes.buttonLabel }}
            onClick={handleAgree}
          >
            <CloudDownloadIcon className={classes.buttonIcon} />
            {Strings.accept}
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
};

Agreement.propTypes = {
  classes: PropTypes.object.isRequired,
  minReqOS: PropTypes.shape({
    // minimum requirement operating system
    version: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
  }).isRequired,
  urls: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func.isRequired,
};

export default withStyles(styles)(Agreement);
