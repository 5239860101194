import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
    backgroundColor: theme.palette.background.offset,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  image: {
    maxWidth: '100%', // IE
    width: '100%', // IE
  },
});

const Intro = ({ classes, title, body, media }) => (
  <Grid
    id="intro-section"
    container
    spacing={0}
    alignItems="center"
    className={classes.root}
  >
    <Grid item xs={1} md={1} />
    <Grid item xs={10} md={media ? 5 : 10}>
      <Typography variant="headline" className={classes.title}>
        {title}
      </Typography>
      {body}
    </Grid>
    <Hidden xsUp={!media}>
      <Grid item xs={1} md={1} />
      <Hidden mdUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid item xs={10} md={4}>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          {media}
        </Grid>
      </Grid>
    </Hidden>
    <Grid item xs={1} md={1} />
  </Grid>
);

Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.node,
  media: PropTypes.node,
};

export default withStyles(styles)(Intro);
