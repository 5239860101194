import PropTypes from 'prop-types';
import React from 'react';
import TeaserImageChrome from '../../common/images/thumbnails/vb-vs-chrome.png';
import TeaserImageEdge from '../../common/images/thumbnails/vb-vs-edge.png';
import TeaserImageFirefox from '../../common/images/thumbnails/vb-vs-firefox.png';
import TeaserImageSafari from '../../common/images/thumbnails/vb-vs-safari.png';
import useragent from 'useragent';

const VIDEO_INFO = {
  chrome: {
    humanName: 'Chrome',
    videoId: '8gbuetb55k',
    src: 'https://fast.wistia.net/embed/iframe/8gbuetb55k?videoFoam=true',
    title: 'Viasat Browser Vs. Chrome',
    name: 'viasat-browser-vs-chrome',
    teaserImage: TeaserImageChrome,
  },
  firefox: {
    humanName: 'Firefox',
    videoId: 'pdqlt6wjf6',
    src: 'https://fast.wistia.net/embed/iframe/pdqlt6wjf6?videoFoam=true',
    title: 'Viasat Browser Vs. Firefox',
    name: 'viasat-browser-vs-firefox',
    teaserImage: TeaserImageFirefox,
  },
  safari: {
    humanName: 'Safari',
    videoId: 'u7o70q5n5q',
    src: 'https://fast.wistia.net/embed/iframe/u7o70q5n5q?videoFoam=true',
    title: 'Viasat Browser Vs. Safari',
    name: 'viasat-browser-vs-safari',
    teaserImage: TeaserImageSafari,
  },
  edge: {
    humanName: 'Edge',
    videoId: 'ct8yn97ptw',
    src: 'https://fast.wistia.net/embed/iframe/ct8yn97ptw?videoFoam=true',
    title: 'Viasat Browser Vs. Edge',
    name: 'viasat-browser-vs-edge',
    teaserImage: TeaserImageEdge,
  },
};

export const getVideoInfo = (family) => {
  if (family === '*') return Object.keys(VIDEO_INFO).map((k) => VIDEO_INFO[k]);

  const BROWSER =
    family || useragent.parse(navigator.userAgent).family.toLowerCase();

  if (BROWSER.includes('firefox')) {
    return VIDEO_INFO.firefox;
  } else if (BROWSER.includes('ie') || BROWSER.includes('edge')) {
    return VIDEO_INFO.edge;
  } else if (BROWSER.includes('safari')) {
    return VIDEO_INFO.safari;
  } else {
    // default to Chrome
    return VIDEO_INFO.chrome;
  }
};

class WistiaVideo extends React.Component {
  componentDidMount() {
    if (this.props.autoPlay) {
      window._wq = window._wq || [];
      window._wq.push({
        id: this.props.videoId,
        onReady: function (video) {
          // only works on desktop
          video.play();
        },
      });
    }
  }

  render() {
    let { src, title, name } = this.props;
    return (
      <iframe
        src={src}
        title={title}
        frameBorder={0}
        scrolling="no"
        name={name}
        className="wistia_embed"
        allowtransparency="true"
        allowFullScreen="true"
        mozallowfullscreen="true"
        msallowfullscreen="true"
        oallowfullscreen="true"
        webkitallowfullscreen="true"
      />
    );
  }
}

WistiaVideo.defaultProps = {
  autoPlay: false,
};

WistiaVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
};

export default WistiaVideo;
