import Blue from './Blue';
import Hero from './Hero';
import Intro from './Intro';
import Links from './Links';
import PropTypes from 'prop-types';
import React from 'react';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  body: {},
});

const Feature = ({ classes, copy }) => (
  <div className={classes.root}>
    {copy.hero ? <Hero {...copy.hero} /> : null}
    <Gutter className={classes.body}>
      <div className={classes.body}>
        {copy.intro ? <Intro {...copy.intro} /> : null}
        {copy.blue ? <Blue {...copy.blue} /> : null}
        {copy.links ? <Links {...copy.links} /> : null}
      </div>
    </Gutter>
  </div>
);

Feature.propTypes = {
  classes: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired, // TODO: validate top-level structure
};

export default withStyles(styles)(Feature);
