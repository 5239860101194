import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import strings from './Strings.js';

const styles = (theme) => ({
  root: {},
});

const Eula = ({ classes, locale }) => {
  let privacyPolicyLink;

  if (locale) {
    privacyPolicyLink = `/intl/${locale}/privacy-policy`;
    strings.setLanguage(locale);
  } else {
    privacyPolicyLink = `/privacy-policy`;
  }

  return (
    <div>
      <Typography paragraph variant="title">
        {strings.Title}
      </Typography>
      <Typography paragraph variant="subheading">
        {strings.EndUserLicenseAgreement}
      </Typography>
      <Typography paragraph>{strings.Paragraph1}</Typography>
      <Typography paragraph>
        {strings.formatString(
          strings.Paragraph2,
          <strong>{strings.PrivacyPolicy}</strong>,
          <strong>
            <a href={privacyPolicyLink}>{strings.LinkHere}</a>
          </strong>,
          <a href={`http://www.exede.com/legal`}>http://www.exede.com/legal</a>
        )}
      </Typography>
      <ol>
        <li>
          <Typography>
            <strong>{strings.Item1Title}</strong>
          </Typography>
        </li>
        <ol type="a">
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1a,
                <strong>{strings.Item1aTitle}</strong>,
                <strong>{strings.PrivacyNotice}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1b,
                <strong>{strings.Item1bTitle}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1c,
                <strong>{strings.Item1cTitle}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1d,
                <strong>{strings.Item1dTitle}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1e,
                <strong>{strings.Item1eTitle}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1f,
                <strong>{strings.Item1fTitle}</strong>
              )}
            </Typography>
          </li>
          <li>
            <Typography>
              {strings.formatString(
                strings.Item1g,
                <strong>{strings.Item1gTitle}</strong>
              )}
            </Typography>
          </li>
        </ol>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item2,
              <strong>{strings.Item2Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item3,
              <strong>{strings.Item3Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item4,
              <strong>{strings.Item4Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item5,
              <strong>{strings.Item5Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item6,
              <strong>{strings.Item6Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item7,
              <strong>{strings.Item7Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item8,
              <strong>{strings.Item8Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item9,
              <strong>{strings.Item9Title}</strong>
            )}
          </Typography>
        </li>

        <li>
          <Typography>
            {strings.formatString(
              strings.Item10,
              <strong>{strings.Item10Title}</strong>
            )}
          </Typography>
        </li>
      </ol>
    </div>
  );
};

Eula.propTypes = {
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string,
};

export default withStyles(styles)(Eula);
