import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
    backgroundColor: theme.palette.background.offset,
  },
  image: {
    maxWidth: '100%',
    width: '100%', // for IE
  },
});

const Links = ({ classes, title, body, imgSrc }) => (
  <Grid
    id="links-section"
    container
    spacing={0}
    alignItems="center"
    className={classes.root}
  >
    <Grid item xs={1} md={1} />
    <Grid item xs={10} md={imgSrc ? 5 : 10}>
      <Typography variant="headline" paragraph>
        {title}
      </Typography>
      {body}
    </Grid>
    <Hidden xsUp={!imgSrc}>
      <Hidden mdUp>
        <Grid item xs={1} />
        <Grid item xs={1} />
      </Hidden>
      <Grid item xs={10} md={5}>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <img className={classes.image} src={imgSrc} alt="Illustration" />
        </Grid>
      </Grid>
    </Hidden>
    <Grid item xs={1} md={1} />
  </Grid>
);

Links.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.node,
  imgSrc: PropTypes.string,
};

export default withStyles(styles)(Links);
