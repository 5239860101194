import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import Rules from './Rules';
import Winners from './Winners';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  dividerMargin: {
    margin: theme.spacing.unit * 5 + 'px 0',
  },
});

const SweepstakesRules = ({ classes }) => (
  <Gutter>
    <Winners />
    <Divider variant="middle" className={classes.dividerMargin} />
    <Rules />
  </Gutter>
);

SweepstakesRules.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SweepstakesRules);
