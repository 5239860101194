import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    title: 'Thank you for submitting feedback.',
    care: {
      title: 'For issues with your Viasat Internet account or service',
      body: 'Please visit {link1} or {link2} for help with Viasat Internet.',
    },
    browser: {
      title: 'For issues with Viasat Browser',
      body1:
        'If you included an email address in your submission, the Viasat Browser team may get back to you if we need more information to help us understand the issue you reported.',
      body2:
        "Due to the volume of reports, in most cases we won't be able to send you a personal reply. However, if you have other questions about Viasat Browser, please take a look around the {link} to find answers.",
      link: 'FAQs',
    },
  },

  'es-MX': {
    title: 'Gracias por enviar tu retroalimentación.',
    browser: {
      body1:
        'Si incluiste una dirección de correo electrónico en tu envío, nuestro equipo de Viasat Browser puede ponerse en contacto contigo por si requerimos mayor información que nos ayude a comprender el problema que has notificado.',
      body2:
        'Debido al volumen de informes, en la mayoría de los casos no podremos enviarle una respuesta personal. Sin embargo, si tiene otras preguntas sobre el navegador Viasat, consulte {link} para encontrar respuestas.',
      link: 'las preguntas frecuentes',
    },
  },
};

// Setup aliased locales for all country code formats used by the browser.
['es', 'es_MX', 'es-419', 'es_419'].forEach((code) => {
  strings[code] = strings['es-MX'];
});

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
