import classnames from 'classnames';
import DownloadButtons from '../../common/DownloadButtons';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import useragent from 'useragent';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  // md breakpoint - show hero gradient on sm or smaller screens
  '@media (max-width: 600px)': {
    root: {
      background:
        'linear-gradient(to top, ' + theme.palette.primary.main + ', white)',
      paddingTop: '5vh',
      paddingBottom: '5vh',
    },
  },
  // md breakpoint - show hero image on md or larger screens
  '@media (min-width: 600px)': {
    root: {
      backgroundPosition: 'center 14%',
      backgroundSize: 'cover, contain',
      backgroundRepeat: 'no-repeat',
      paddingTop: '5vh',
      paddingBottom: '5vh',
      minHeight: '55vh',
    },
  },
  title: {
    marginBottom: '3vh',
  },
  subtitle: {
    marginBottom: '5vh',
  },
  icon: {
    maxHeight: '20vh',
  },
  xsIcon: {
    maxHeight: '10vh',
    marginBottom: '3vh',
  },
});

const Hero = ({
  width,
  classes,
  title,
  subtitle,
  iconSrc,
  imgSrc,
  heroSrc,
}) => (
  <Grid
    container
    id="hero-section"
    className={classes.root}
    style={
      // only use a background image for sm+ screens
      ['xs'].includes(width) ? {} : { backgroundImage: 'url(' + heroSrc + ')' }
    }
  >
    <Grid container alignItems="center">
      <Grid item xs={1} sm={1} lg={2} />
      <Grid item xs={10} sm={5} lg={4} className={classes.copy}>
        <Typography
          className={classnames(classes.title, classes.block)}
          variant="display2"
        >
          {title}
        </Typography>
        <Grid container alignItems="center">
          <Hidden smUp>
            <Grid item xs={2}>
              <img
                className={classnames(classes.xsIcon, classes.block)}
                alt="icon"
                src={iconSrc}
              />
            </Grid>
            <Grid item xs={1} />
          </Hidden>
          <Grid item xs={9} sm={12}>
            <Typography
              className={classnames(classes.subtitle, classes.block)}
              variant="headline"
            >
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
        <DownloadButtons
          color="secondary"
          size="large"
          agent={useragent.parse(navigator.userAgent)}
          justify="flex-start"
        />
      </Grid>
      <Grid item sm={5} lg={4}>
        <Hidden xsUp>
          <img className={classes.icon} alt="icon" src={iconSrc} />
        </Hidden>
      </Grid>
      <Grid item xs={1} sm={1} lg={2} />
    </Grid>
  </Grid>
);

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconSrc: PropTypes.string.isRequired,
  heroSrc: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles)(Hero));
