import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    fast: 'Fast',
    private: 'Private',
    data: 'Saves Data',
    smart: 'Smart',
    faq: 'FAQ',
    policy: 'Privacy Policy',
    license: 'License',
    feedback: 'Thanks for the Feedback',
    container: 'Container Home Page Instructions',
  },

  'es-MX': {
    fast: 'Rápido',
    private: 'Privado',
    data: 'Ahorra Datos',
    smart: 'Inteligente',
    faq: 'Preguntas Frecuentes',
    policy: 'Política de privacidad',
    license: 'Licencia',
    feedback: 'Gracias por la retroalimentación',
  },
};

// Setup aliased locales for all country code formats used by the browser.
['es', 'es_MX', 'es-419', 'es_419'].forEach((code) => {
  strings[code] = strings['es-MX'];
});

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
