import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  linksSeparator: {
    borderRight: '2px solid ' + theme.palette.secondary.main,
    height: '2em',
  },
});

const _link = (classes, link, key) => (
  <a className={classes.link} href={link.url} key={key}>
    <Button color={link.isPrimary ? 'primary' : 'default'}>{link.label}</Button>
  </a>
);

const _linksSeparator = (classes, links) =>
  links.some((l) => l.isFeaturePage) ? (
    <span className={classes.linksSeparator} />
  ) : null;

const Menu = ({ classes, links }) => (
  <div>
    {links
      .filter((link) => link.isFeaturePage)
      .map((link, i) => _link(classes, link, i))}
    {_linksSeparator(classes, links)}
    {links
      .filter((link) => !link.isFeaturePage)
      .map((link, i) => _link(classes, link, i))}
  </div>
);

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default withStyles(styles)(Menu);
