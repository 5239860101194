import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import { withStyles } from '@material-ui/core/styles';
require('useragent/features');

const styles = (theme) => ({
  playStore: {
    height: theme.spacing.unit * 8,
  },
});

const Android = ({ classes, agent }) => (
  <a
    href="https://play.google.com/store/apps/details?id=com.viasat.browser"
    id="android-download"
    className="download"
  >
    <img
      className={classes.playStore}
      alt={Strings.download_android}
      title={Strings.download_android}
      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    />
  </a>
);

Android.propTypes = {
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
};

export default withStyles(styles)(Android);

const MIN_VERSION = '4.1'; // TODO: seems to be broken...
const SUPPORTED_FAMILIES = ['Android'];

export const isSupportedVersionOfAndroid = (agent) =>
  SUPPORTED_FAMILIES.includes(agent.os.family) &&
  agent.satisfies('>=' + MIN_VERSION);
