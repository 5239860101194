import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Gutter } from '../../Root';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
    root: {
      /* IE10+ styles */
      marginTop: theme.spacing.unit * 5,
      minHeight:
        'calc(100vh - ' +
        theme.spacing.unit * 9 + // header
        'px - ' +
        theme.spacing.unit * 5 + // error page top margin
        'px - ' +
        theme.spacing.unit * 8 + // minimum footer height
        'px)',
    },
  },
});

const ErrorPage = ({ classes, error }) => (
  <Gutter always>
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{error}</Grid>
    </Grid>
  </Gutter>
);

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.node.isRequired,
};

export default withStyles(styles)(ErrorPage);
