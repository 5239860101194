import Policy from './Policy';
import { Gutter } from '../../Root';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

const PrivacyPolicy = ({ classes, match }) => (
  <Gutter>
    <Policy locale={match.params.locale} />
  </Gutter>
);

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicy);
