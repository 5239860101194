import React from 'react';

import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  feedback: {
    paddingTop: '30px',
  },
});

const FeedbackConfirmation = ({ classes }) => (
  <Gutter>
    <div className={classes.feedback}>
      <Typography variant="display2" paragraph>
        {Strings.title}
      </Typography>

      <Typography variant="headline" paragraph>
        {Strings.care.title}
      </Typography>
      <Typography paragraph>
        {Strings.formatString(Strings.care.body, {
          link1: <a href="https://my.viasat.com">https://my.viasat.com</a>,
          link2: <a href="https://help.viasat.com">https://help.viasat.com</a>,
        })}
      </Typography>

      <Typography variant="headline" paragraph>
        {Strings.browser.title}
      </Typography>
      <Typography paragraph>{Strings.browser.body1}</Typography>
      <Typography paragraph>
        {Strings.formatString(Strings.browser.body2, {
          link: <a href="/faq">{Strings.browser.link}</a>,
        })}
      </Typography>
    </div>
  </Gutter>
);

export default withStyles(styles)(FeedbackConfirmation);
