import Agreement from './Agreement';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Confirmation from './Confirmation';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  smallButton: {
    borderRadius: '25px',
    margin: '10px',
  },
  largeButton: {
    borderRadius: '100px',
    margin: '30px 10px 10px 10px',
  },

  smallButtonLabel: {
    textTransform: 'capitalize',
    padding: '4px',
  },
  largeButtonLabel: {
    textTransform: 'capitalize',
    padding: '15px',
    fontSize: '28px',
  },

  buttonIcon: {
    marginRight: '10px',
  },
});

class Desktop extends React.Component {
  state = {
    agreement: { open: false },
    confirmation: { open: false },
  };

  handleClickOpenAgreement = () => {
    this.setState({ agreement: { open: true } });
  };

  handleCloseAgreement = (didAgree) => {
    this.setState({
      agreement: { open: false },
      confirmation: { open: didAgree },
    });
  };

  // reason can be 'clickaway' or 'timeout'
  handleCloseConfirmation = (event, reason) => {
    if (reason === 'clickaway') {
      return; // ignore clickaways
    }
    this.setState({ confirmation: { open: false } });
  };

  render() {
    const { classes, minReqOS, color, size, supported, urls } = this.props;
    let downloadButtonText;

    if (supported) {
      downloadButtonText = Strings.download_now;
    } else {
      downloadButtonText = Strings.formatString(Strings.download_for_short, {
        target: minReqOS.family,
      });
    }

    return (
      <div>
        <Button
          variant="contained"
          color={color}
          className={classnames(
            size === 'small' ? classes.smallButton : classes.largeButton,
            'desktop-download',
            'download'
          )}
          classes={{
            label:
              size === 'small'
                ? classes.smallButtonLabel
                : classes.largeButtonLabel,
          }}
          onClick={this.handleClickOpenAgreement}
        >
          <CloudDownloadIcon className={classes.buttonIcon} />
          {downloadButtonText}
        </Button>
        <Agreement
          open={this.state.agreement.open}
          urls={urls}
          handleClose={this.handleCloseAgreement.bind(this, false)}
          handleAgree={this.handleCloseAgreement.bind(this, true)}
          minReqOS={minReqOS}
        />
        <Confirmation
          open={this.state.confirmation.open}
          handleClose={this.handleCloseConfirmation}
        />
      </div>
    );
  }
}

Desktop.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  supported: PropTypes.bool.isRequired,
  minReqOS: PropTypes.shape({
    // minimum requirement operating system
    version: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
  }).isRequired,
  urls: PropTypes.object.isRequired,
};

export default withStyles(styles)(Desktop);
