import Desktop from './Desktop';
import PropTypes from 'prop-types';
import React from 'react';
require('useragent/features');

export const Linux = ({ color, size, agent, supported }) => (
  <div id="linux-download" className="download">
    <Desktop
      color={color}
      size={size}
      supported={supported}
      minReqOS={{ family: 'Linux', version: '' }}
      urls={{
        Stable: 'https://browser.viasat.com/faq#install-linux',
        // Alpha: '',
        // PreAlpha: '',
        // Canary: '',
      }}
    />
  </div>
);

Linux.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  supported: PropTypes.bool.isRequired,
  agent: PropTypes.object.isRequired, // from useragent package
};

export default Linux;

export const isSupportedVersionOfLinux = (agent) =>
  agent.source.includes('Linux x86_64');
