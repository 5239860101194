import ContainerHelp from '../Pages/Container';
import DocumentTitle from '../common/DocumentTitle';
import ErrorPage from '../Pages/Error';
import FAQs from '../Pages/FAQs';
import FeedbackConfirmation from '../Pages/FeedbackConfirmation';
import Home from '../Pages/Home';
import License from '../Pages/License';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import PropTypes from 'prop-types';
import React from 'react';
import Strings from './Strings.js';
import SweepstakesRules from '../Pages/SweepstakesRules';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Fast, Private, SavesData, Smart } from '../Pages/Features';
import { NotFound } from '../common/Error';

const TITLE_PRIMARY = 'Viasat Browser';
const TITLE_SEPARATOR = ' | ';
const TITLE_PREFIX = TITLE_PRIMARY + TITLE_SEPARATOR;

// Each item in this list should be a dictionary with the same keys (unless
// marked otherwise). See the in-line descriptions for details.
export const ROUTES = [
  {
    // General routing config for this page
    path: '/', // The react-router path pattern
    exact: true, // Use exact-match route pattern matching
    title: TITLE_PRIMARY, // The page title
    component: Home, // The component to display
    link: {
      // Config for the nav link for this page
      url: '/', // the href for the nav button for this page
      label: 'Viasat Browser', // The text for the nav button for this page
      isPrimary: true, // Use the primary color for the button text
      isFeaturePage: true, // Treat this page as a VB feature page
      hide: false, // Optional: hide the nav button for this page
    },
  },
  {
    path: '/fast',
    exact: true,
    title: TITLE_PREFIX + Strings.fast,
    component: Fast,
    link: {
      url: '/fast',
      label: Strings.fast,
      isPrimary: false,
      isFeaturePage: true,
    },
  },
  {
    path: '/private',
    exact: true,
    title: TITLE_PREFIX + Strings.private,
    component: Private,
    link: {
      url: '/private',
      label: Strings.private,
      isPrimary: false,
      isFeaturePage: true,
    },
  },
  {
    path: '/saves-data',
    exact: true,
    title: TITLE_PREFIX + Strings.data,
    component: SavesData,
    link: {
      url: '/saves-data',
      label: Strings.data,
      isPrimary: false,
      isFeaturePage: true,
    },
  },
  {
    path: '/smart',
    exact: true,
    title: TITLE_PREFIX + Strings.smart,
    component: Smart,
    link: {
      url: '/smart',
      label: Strings.smart,
      isPrimary: false,
      isFeaturePage: true,
    },
  },
  {
    path: '/faq',
    exact: true,
    title: TITLE_PREFIX + Strings.faq,
    component: FAQs,
    link: {
      url: '/faq',
      label: Strings.faq,
      isPrimary: false,
      isFeaturePage: false,
    },
  },
  {
    path: '/privacy-policy',
    exact: true,
    title: TITLE_PREFIX + Strings.policy,
    component: PrivacyPolicy,
    link: {
      url: '/privacy-policy',
      label: Strings.policy,
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/intl/:locale/privacy-policy',
    exact: true,
    title: TITLE_PREFIX + Strings.policy,
    component: PrivacyPolicy,
    link: {
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/intl/:locale/license',
    exact: true,
    title: TITLE_PREFIX + 'License',
    component: License,
    link: {
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/license',
    exact: true,
    title: TITLE_PREFIX + Strings.license,
    component: License,
    link: {
      url: '/license',
      label: Strings.license,
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/sweepstakes',
    exact: true,
    title: TITLE_PREFIX + 'Sweepstakes Rules',
    component: SweepstakesRules,
    link: {
      url: '/sweepstakes',
      label: 'Sweepstakes Rules',
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/feedback-confirmation',
    exact: true,
    title: TITLE_PREFIX + Strings.feedback,
    component: FeedbackConfirmation,
    link: {
      url: '/feedback-confirmation',
      label: Strings.feedback,
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
  {
    path: '/container',
    exact: true,
    title: TITLE_PREFIX + Strings.container,
    component: ContainerHelp,
    link: {
      url: '/container',
      label: Strings.container,
      isPrimary: false,
      isFeaturePage: false,
      hide: true,
    },
  },
];

// redirect '/x.html' to '/x'
const REDIRECTS = ROUTES.map((route, i) => ({
  from: route.path + '.html',
  to: route.path,
})).concat([
  {
    from: '/privacypolicy.html',
    to: '/privacy-policy',
  },
  {
    from: '/includes/eula.html',
    to: '/license',
  },
  {
    from: '/about(.html)?',
    to: '/',
  },
  {
    from: '/installers(.html)?',
    to: '/',
  },
]);

const Routes = ({ routes }) => (
  <Router>
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <DocumentTitle title={route.title}>
              <route.component {...props} />
            </DocumentTitle>
          )}
        />
      ))}
      {REDIRECTS.map((redirect, i) => (
        <Redirect key={i} from={redirect.from} to={redirect.to} />
      ))}
      <Route render={(props) => <ErrorPage error={<NotFound />} />} />
    </Switch>
  </Router>
);

Routes.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Routes;
