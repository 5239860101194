import React from 'react';

import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Strings from './Strings.js';
import Step4 from './step4.png';
import { Gutter } from '../../Root';

const styles = (theme) => ({
  container: {
    paddingTop: '30px',
  },
  codeSpan: {
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 5px',
    whiteSpace: 'nowrap',
  },
  center: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  image: {
    border: 'solid 1px ' + theme.palette.background.border,
    marginTop: theme.spacing.unit * 3 + 'px',
    marginBottom: theme.spacing.unit * 3 + 'px',
    borderRadius: '4px',
    width: '400px',
  },
});

const ContainerHelp = ({ classes }) => (
  <Gutter>
    <div className={classes.container}>
      <Typography variant="display2" paragraph>
        {Strings.title}
      </Typography>

      <Typography component={'span'} paragraph>
        <Typography variant="subheading">{Strings.install}</Typography>
        <ul>
          <li>
            {Strings.formatString(Strings.android, {
              link: (
                <a href="https://play.google.com/store/apps/details?id=com.viasat.browser&hl=en_US&gl=US">
                  {Strings.android_store}
                </a>
              ),
            })}
          </li>
          <li>
            {Strings.formatString(Strings.ios, {
              link: (
                <a href="https://apps.apple.com/us/app/viasat-browser/id1297568937">
                  {Strings.ios_store}
                </a>
              ),
            })}
          </li>
        </ul>
      </Typography>

      <Typography component={'span'} paragraph>
        <Typography variant="subheading">{Strings.configure}</Typography>
        <ol>
          <li>{Strings.step1}</li>
          <li>
            {Strings.formatString(Strings.step2, {
              link: <code className={classes.codeSpan}>viasat://flags</code>,
            })}
          </li>
          <li>
            {Strings.formatString(Strings.step3, {
              search: <code className={classes.codeSpan}>Remote NTP</code>,
            })}
          </li>
          <li>
            {Strings.formatString(Strings.step4, {
              title: (
                <code className={classes.codeSpan}>
                  Viasat Browser Remote NTP Variant
                </code>
              ),
            })}
            <div className={classnames(classes.center)}>
              <img
                className={classnames(classes.image, classes.wideImage)}
                src={Step4}
                alt="Example Step 4"
              />
            </div>
          </li>
          <li>
            {Strings.step5}
            <ul>
              <li>
                <code className={classes.codeSpan}>{Strings.step5a1}</code>
                <br />
                {Strings.step5a2}
              </li>
              <li>
                <code className={classes.codeSpan}>{Strings.step5b1}</code>
                <br />
                {Strings.step5b2}
              </li>
            </ul>
          </li>
          <li>
            {Strings.step6}
            <ul>
              <li>{Strings.step6a}</li>
              <li>{Strings.step6b}</li>
            </ul>
          </li>
          <li>{Strings.step7}</li>
        </ol>

        <Typography component={'span'} paragraph>
          {Strings.formatString(Strings.help, {
            link: <a href="mailto:browser@viasat.com">browser@viasat.com</a>,
          })}
        </Typography>
      </Typography>
    </div>
  </Gutter>
);

export default withStyles(styles)(ContainerHelp);
