import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  titleCase: {
    textTransform: 'capitalize',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
});

const WINNERS_ARR = [
  {
    name: 'Howard Simpson',
    month: 'January 2019',
  },
];

const Winners = ({ classes }) => (
  <div>
    <Typography variant="title" className={classes.titleCase} paragraph>
      Viasat Browser Reward Sweepstakes Winners
    </Typography>
    <ul>
      {WINNERS_ARR.map((winner) => (
        <li>
          <Typography>
            {winner.month}: {winner.name}
          </Typography>
        </li>
      ))}
    </ul>
  </div>
);

Winners.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Winners);
